import React, { Component } from 'react';
import { connect } from 'react-redux';

import { sendSignalsAction, toggleSignalsAction } from 'src/redux/actions';

export const SHOW = 'SHOW';

// Wrapper
export class DisplayWrapperComponent extends Component {
  componentDidMount() {
    this.props.setSignalToToggle();
  }
  render() {
    return this.props.display && this.props.children;
  }
}

export const mapStateToWrapperProps = (state, ownProps) => {
  let display = false;
  try {
    display = !!state.component_signals?.[ownProps.targetId]?.[SHOW];
  } catch (e) {
    console.log('Error', e);
  }
  return { display: display };
};

export const mapDispatchToWrapperProps = (dispatch, ownProps) => {
  return {
    setSignalToToggle: () => {
      dispatch(
        sendSignalsAction({
          [ownProps.targetId]: { [SHOW]: !!ownProps.visible },
        }),
      );
    },
  };
};

export const DisplayWrapper = connect(
  mapStateToWrapperProps,
  mapDispatchToWrapperProps,
)(DisplayWrapperComponent);

// Toggle
export class DisplayToggleWrapperComponent extends Component {
  render() {
    const {
      component: _component,
      targetId: _targetId,
      children,
      toggleDisplayComponent,
      ...rest
    } = this.props;
    const PresentationComponent = this.props.component;
    return (
      <PresentationComponent {...rest} onClick={toggleDisplayComponent}>
        {children}
      </PresentationComponent>
    );
  }
}

export const mapDispatchToPropsToggle = (dispatch, ownProps) => {
  return {
    toggleDisplayComponent: () => {
      dispatch(toggleSignalsAction({ [ownProps.targetId]: [SHOW] }));
    },
  };
};

export const DisplayToggleWrapper = connect(
  undefined,
  mapDispatchToPropsToggle,
)(DisplayToggleWrapperComponent);
