import {
  iconChevronDown,
  iconChevronUp,
  iconForum,
  iconLocked,
} from '@wfp/icons';
import { Icon, Tag } from '@wfp/ui';
import { formatDistanceToNow } from 'date-fns';
import React, { FC, useState } from 'react';

import { IIssue } from 'src/types';
import { getDateWithTimezone, getUserInfo } from 'src/utils';

interface Props {
  issue: IIssue;
}

const IssueItem: FC<Props> = ({ issue }) => {
  const [isOpen, setIsOpen] = useState(!issue.closed);

  return (
    <div className="io-issue-item" key={issue.pk}>
      <div className="io-issue-item-header">
        <div className="io-issue-item-left-col">
          {issue.restored && (
            <Tag className="thread-restored-label" type="custom">
              Restored
            </Tag>
          )}
          {issue.closed && (
            <Tag className="thread-closed-label" type="custom">
              Closed
            </Tag>
          )}
          {issue.important && <Tag type="error">High Importance</Tag>}
          <div className="io-issue-item-left-col-wrapper-content">
            <Icon
              width="30"
              height="30"
              fill="grey"
              description="Thread"
              icon={issue.closed ? iconLocked : iconForum}
              className="acr-comment-title-icon"
            />
            <div className="io-issue-item-left-col-content">
              <div className="io-issue-item-title">{issue.title}</div>
              <div className="io-issue-item-info">
                <div className="io-issue-item-author">
                  By: {getUserInfo(issue?.user)}
                </div>
                <div className="io-issue-item-id">Comment ID: {issue.pk}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="io-issue-item-right-col">
          <div className="io-iirc-date">
            <div>{getDateWithTimezone(issue?.date)}</div>
            <div>{`${formatDistanceToNow(issue?.date?.date)} ago`}</div>
          </div>
          <Icon
            width="30"
            height="30"
            fill="grey"
            description={isOpen ? 'Collapse' : 'Expand'}
            icon={isOpen ? iconChevronUp : iconChevronDown}
            className="acr-comment-title-icon acr-comment-accordion-icon"
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
      </div>
      {isOpen && (
        <>
          <div className="io-item-separator"></div>
          <div className="io-issue-item-content">{issue.content}</div>
          {issue.comments?.map(comment => {
            return (
              <div className="io-item-comment" key={comment.pk}>
                <div className="io-item-comment-header">
                  <div className="io-item-comment-left-col">
                    <div>
                      <Icon
                        width="30"
                        height="30"
                        fill="grey"
                        description="Thread"
                        icon={iconForum}
                        className="acr-comment-title-icon"
                      />
                    </div>
                    <div>
                      <div className="io-item-comment-title">
                        Reply by: {getUserInfo(comment?.user)}
                      </div>
                      Comment ID: {comment.pk}
                    </div>
                  </div>
                  <div className="io-item-comment-right-col">
                    <div>{getDateWithTimezone(comment?.date)}</div>
                    <div>{`${formatDistanceToNow(issue?.date?.date)} ago`}</div>
                  </div>
                </div>
                {comment.content}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default IssueItem;
