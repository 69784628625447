import React, { FC } from 'react';

import { IUserData } from 'src/types';

interface Props {
  userData: IUserData;
}

const UserInfoBox: FC<Props> = ({ userData }) => {
  const { first_name, last_name, username, email } = userData;

  return (
    <div className="user-data-box">
      <div className="user-data-box-name">
        {first_name && last_name && (
          <div>
            <div className="user-data-box-label">Full name</div>
            <div>{`${first_name} ${last_name}`}</div>
          </div>
        )}
        <div>
          <div className="user-data-box-label">Username</div>
          <div>{username}</div>
        </div>
      </div>
      <div>
        <div className="user-data-box-label">Email</div>
        <div className="user-data-box-email">{email}</div>
      </div>
    </div>
  );
};

export default UserInfoBox;
