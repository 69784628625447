import { Form, Loading, Modal } from '@wfp/ui';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { API_HOST, rolesToLabels } from 'src/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { IEditUserFormFields, IOptionValue, IUserData } from 'src/types';
import { showNotification } from 'src/utils';
import EditUserRepeatableFields from '../EditUserRepeatableFields';
import UserInfoBox from '../UserInfoBox';

interface Props {
  userData: IUserData;
  roles: IOptionValue[];
  offices: IOptionValue[];
  onClose: () => void;
  open: boolean;
}

const EditUserModal: FC<Props> = ({
  userData: userDataProp,
  roles: rolesList,
  offices: officesList,
  onClose,
  open,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<IEditUserFormFields>({});

  const uData = useMemo(() => {
    return getUserInitData();
  }, [userDataProp]);

  useEffect(() => {
    reset({ roles: uData });
  }, [reset, uData]);

  function getUserInitData() {
    if (!userDataProp || !userDataProp.roles) return [];

    const updatedData = [];

    userDataProp.roles.forEach(item => {
      const roleKey = Object.keys(item)[0];
      updatedData.push({
        role: matchRoleValue(rolesList, roleKey),
        office: item[roleKey].map(el => {
          return matchValue(officesList, el);
        }),
      });
    });
    return updatedData;
  }

  function matchValue(obj, label) {
    let result = null;
    obj.forEach(instance => {
      if (instance.label === label) {
        result = instance.value;
      }
    });
    return result;
  }

  function matchRoleValue(obj, label) {
    let result = null;
    obj.forEach(instance => {
      if (instance.label === rolesToLabels[label]) {
        result = instance.value;
      }
    });
    return result;
  }

  function handleUpdateBtn() {
    handleSubmit(saveUser)();
  }

  function saveUser(data: IEditUserFormFields) {
    const url = `${API_HOST}/api/users/${userDataProp.id}`;

    setIsSaving(true);

    apiCallAsync(url, data, 'put')
      .then(response => {
        if (response.status === 200) {
          const hasName = userDataProp.first_name && userDataProp.last_name;
          const name = `${userDataProp.first_name} ${userDataProp.last_name}`;
          showNotification(
            `${hasName ? name : userDataProp.username} updated.`,
            'success',
          );
        }
        setIsSaving(false);
        onClose();
      })
      .catch(error => {
        setIsSaving(false);
        if (error.response) {
          showNotification(
            `${error.message}: ${error.response.data.detail}`,
            'error',
          );
        }
      });
  }

  if (isSaving) {
    return <Loading withOverlay={true} className="page-loader" />;
  }

  return (
    <>
      <Modal
        open={open}
        onRequestSubmit={handleUpdateBtn}
        // @ts-expect-error: Wrong type check in wfp UI Kit
        onSecondarySubmit={() => {
          onClose();
        }}
        onRequestClose={() => {
          onClose();
        }}
        modalLabel="Edit User"
        modalHeading={`${userDataProp.first_name} ${userDataProp.last_name}`}
        primaryButtonText="Update User"
        secondaryButtonText="Cancel"
        iconDescription="Close the modal"
        id="edit-user-modal"
        className="edit-user-modal-class"
        selectorPrimaryFocus={false}
        wide
      >
        <>
          <UserInfoBox userData={userDataProp} />
          <Form id="edit-user-form">
            <EditUserRepeatableFields
              name="roles"
              control={control}
              errors={errors}
              appendValue={{ role: 0, office: [] }}
              rolesList={rolesList}
              officesList={officesList.filter(({ value }) => value !== 0)}
            />
          </Form>
        </>
      </Modal>
    </>
  );
};

export default EditUserModal;
