import React, { FC, useEffect, useState } from 'react';

import Reload from 'src/assets/images/reload-icon.svg';
import { API_HOST } from 'src/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { showNotification } from 'src/utils';

const restoreUrl = API_HOST + '/api/restore-roles/';

interface Props {
  closeMenu: () => void;
  fetchData: () => void;
}

const RestoreUserBtn: FC<Props> = ({ closeMenu, fetchData }) => {
  const [lastRestoreDate, setLastRestoreDate] = useState(null);
  const [isRestoring, setIsRestoring] = useState(false);

  useEffect(() => {
    if (!isRestoring) getLastRestore();
  }, [isRestoring]);

  async function getLastRestore() {
    const restoreDate = await apiCallAsync(restoreUrl);

    if (
      !restoreDate?.data?.message ||
      restoreDate?.data?.message === 'No Backup File'
    ) {
      return;
    }

    setLastRestoreDate(restoreDate.data.message);
  }

  async function restoreRoles() {
    closeMenu();
    showNotification('Restoring users roles', 'info', {
      autoClose: false,
    });

    setIsRestoring(true);

    try {
      const restoreResult = await apiCallAsync(restoreUrl, {}, 'post');
      if (
        !restoreResult?.data?.message ||
        restoreResult?.data?.message !== 'Successful restore'
      ) {
        throw Error;
      }

      setIsRestoring(false);
      showNotification('Users roles restored successfully', 'success', {
        autoClose: false,
      });
      fetchData();
    } catch {
      showNotification(
        'Errore while restoring users roles. Please try again later',
        'error',
        {
          autoClose: false,
        },
      );
    }
  }

  if (!lastRestoreDate) {
    return <></>;
  }

  return (
    <div
      onClick={restoreRoles}
      className={`restore-users-btn ${isRestoring ? 'disabled' : ''}`}
    >
      <div className="restore-users-btn-main">
        <img src={Reload} />
        <div>Restore Users</div>
      </div>
      <div className="restore-users-btn-date">{lastRestoreDate}</div>
    </div>
  );
};

export default RestoreUserBtn;
