import React, { FC } from 'react';
import slug from 'slug';

import { ISection } from 'src/types';

interface Props {
  sections: ISection[];
}

interface PropsIssueOverviewSidebarMenu {
  sections: ISection[];
  level?: string;
}

const IssueOverviewSidebarMenu: FC<PropsIssueOverviewSidebarMenu> = ({
  sections,
  level,
}) => {
  return !!sections && sections.length > 0 ? (
    <ul>
      {sections.map((section, index) => {
        const key = `${level ? level + '-' : ''}${index}`,
          currentSlug = `#${slug(section.current_title)}`;

        return (
          <li
            key={key}
            className={currentSlug === location.hash ? 'expanded' : ''}
          >
            <div className="section-title">
              <a href={currentSlug}>{section.current_title}</a>
            </div>
            <IssueOverviewSidebarMenu
              sections={section.children_list}
              level={key}
            />
          </li>
        );
      })}
    </ul>
  ) : null;
};

const IssuesOverviewSidebar: FC<Props> = ({ sections }) => {
  return (
    <div className="issues-overview-sidebar">
      <IssueOverviewSidebarMenu sections={sections} />
    </div>
  );
};

export default IssuesOverviewSidebar;
