import { iconAdd } from '@wfp/icons';
import { Button } from '@wfp/ui';
import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import ReactSelect from 'react-select';

import { IOptionValue } from 'src/types';

interface Props {
  name: string;
  control: any;
  errors: any;
  appendValue: any;
  rolesList: IOptionValue[];
  officesList: IOptionValue[];
}

const EditUserRepeatableFields: FC<Props> = ({
  name,
  control,
  errors,
  appendValue,
  rolesList,
  officesList,
}) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name,
  });

  const fieldsVal = useWatch({
    control,
    name,
  });

  function handleRemove(index: number) {
    if (fields.length > 1) {
      remove(index);
    } else {
      update(index, appendValue);
    }
  }

  function validateRolesSelect(val: number) {
    if (val === 0) return 'Role field is mandatory';
    return true;
  }

  function validateOfficesSelect(val: number[]) {
    if (isEmpty(val)) return 'Offices field is mandatory';
    return true;
  }

  function getErrorMessage(index: number, fieldName: string) {
    const error = errors?.[name]?.[index]?.[fieldName];
    return error?.message;
  }

  function getOfficesValue(officesValue: number[]) {
    if (!officesValue) return;
    return officesList.filter(office => officesValue.includes(office.value));
  }

  const filteredRolesList = useMemo(() => {
    if (!fieldsVal || !rolesList) return [];

    return rolesList.filter(item => {
      const isPresent = fieldsVal.find(
        selectedRole => selectedRole.role === item.value,
      );
      if (!isPresent) return true;
    });
  }, [fieldsVal, rolesList]);

  return (
    <>
      {fields.map((field, index) => {
        return (
          <div
            className="modal-edit-form user-management role-block"
            id={`block-${index}`}
            key={field.id}
          >
            <div className="edit-user-row">
              <div className="wfp--form-item user-management__role">
                <label htmlFor="edit-role-select" className="wfp--label">
                  Role
                </label>
                <Controller
                  control={control}
                  name={`${name}.${index}.role`}
                  rules={{
                    validate: (val: number) => validateRolesSelect(val),
                  }}
                  render={({ field: { onChange, value, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      id="edit-role-select"
                      className="wfp--react-select-container"
                      classNamePrefix="wfp--react-select-modal"
                      options={filteredRolesList}
                      value={rolesList.find(c => c.value === value)}
                      maxMenuHeight={125}
                      onChange={(val: IOptionValue) => onChange(val.value)}
                    />
                  )}
                />
                <div className="err-msgs-list wfp--form-requirement">
                  {getErrorMessage(index, 'role')}
                </div>
              </div>
              <div className="wfp--form-item user-management__offices">
                <label htmlFor="edit-office-select" className="wfp--label">
                  Offices
                </label>
                <Controller
                  control={control}
                  name={`${name}.${index}.office`}
                  rules={{
                    validate: (val: number[]) => validateOfficesSelect(val),
                  }}
                  render={({ field: { onChange, value, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      id="edit-office-select"
                      className="wfp--react-select-container"
                      classNamePrefix="wfp--react-select-modal"
                      options={officesList}
                      isSearchable
                      isMulti
                      value={getOfficesValue(value)}
                      maxMenuHeight={125}
                      onChange={val => {
                        if (!val) {
                          onChange([]);
                          return;
                        }

                        onChange(val.map(item => item.value));
                      }}
                    />
                  )}
                />
                <div className="err-msgs-list wfp--form-requirement">
                  {getErrorMessage(index, 'office')}
                </div>
              </div>
              <div className="wfp--form-item drop-role-btn">
                <Button
                  kind="danger"
                  onClick={() => {
                    handleRemove(index);
                  }}
                  key={`drop-${index}`}
                  id={`drop-${index}`}
                >
                  Remove Role
                </Button>
              </div>
            </div>
          </div>
        );
      })}
      <Button
        onClick={() => append(appendValue)}
        className="add-role-btn"
        key="add_new_role"
        id="add-role_button"
        kind="ghost"
        icon={iconAdd}
        iconReverse
      >
        Add Another Role
      </Button>
    </>
  );
};

export default EditUserRepeatableFields;
