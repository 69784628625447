import { Loading, Modal } from '@wfp/ui';
import orderBy from 'lodash/orderBy';
import type { FC } from 'react';
import React, { Dispatch, useEffect, useState } from 'react';

import { MULTI_NARRATIVE_VERSIONS } from 'src/constants';
import { getActionAPISource } from 'src/redux/actions';
import { apiCallAsync } from 'src/redux/sagas';
import VersionItem from '../VersionItem';

interface Props {
  title: string;
  onClose: () => void;
  narrativeId: number;
  setSelectedVersion: Dispatch<any>;
  revertEnabled: boolean;
}

interface IVersion {
  change_date: string;
  content: string;
  clean_content: string;
  editor: string;
  id: number;
}

const VersionsModal: FC<Props> = ({
  title,
  onClose,
  narrativeId,
  setSelectedVersion,
  revertEnabled,
}) => {
  const [versions, setVersions] = useState<IVersion[] | null>(null);
  const [isVersionsLoading, setIsVersionsLoading] = useState(false);

  useEffect(() => {
    getVersions();
  }, []);

  async function getVersions() {
    setIsVersionsLoading(true);

    const url = getActionAPISource(MULTI_NARRATIVE_VERSIONS).replace(
      'narrative_pk',
      narrativeId,
    );

    const res = await apiCallAsync(url, {}, 'get');

    setIsVersionsLoading(false);
    if (res.status !== 200) return;

    const orderedVersions = orderBy(
      res.data,
      ({ change_date }) => change_date,
      'desc',
    );

    setVersions(orderedVersions);
  }

  return (
    <Modal
      modalHeading={`${title} versions`}
      onRequestClose={onClose}
      passiveModal
      open
      wide
      className="versions-modal"
    >
      {isVersionsLoading && (
        <Loading className="versions-modal-loader" withOverlay={false} active />
      )}
      {!isVersionsLoading && versions?.length === 0 && <>No versions present</>}
      {versions?.map(({ content, clean_content, editor, change_date, id }) => {
        return (
          <VersionItem
            key={id}
            content={content}
            cleanContent={clean_content}
            editor={editor}
            date={change_date}
            id={id}
            setSelectedVersion={setSelectedVersion}
            revertEnabled={revertEnabled}
          />
        );
      })}
    </Modal>
  );
};

export default VersionsModal;
