import { iconWfpHumEmergencyResponsePos } from '@wfp/icons';
import { Icon, Link, Story, Wrapper } from '@wfp/ui';
import React, { FC } from 'react';

const NotFound: FC = () => {
  return (
    <Wrapper pageWidth="lg" spacing="md">
      <Story className="wfp--story__center wfp--story__full-height">
        <Icon
          description="Not Found"
          icon={iconWfpHumEmergencyResponsePos}
          fill="#007DBC"
          width="200px"
          height="200px"
          style={{ marginBottom: '3rem', transform: 'rotate(10deg)' }}
        />
        <h1 className="wfp--story__title">
          Sorry, we couldn&apos;t find that page
        </h1>
        <p>
          Go back to <Link href="/">Dashboard</Link>
        </p>
      </Story>
    </Wrapper>
  );
};

export default NotFound;
