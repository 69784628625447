import {
  Button,
  Module,
  ModuleBody,
  ModuleHeader,
  Text,
  TextInput,
} from '@wfp/ui';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import { postAcrInfoBox } from 'src/redux/report/actions';
import {
  REPORT_DETAIL_DATA_KEY,
  SECTION_DETAIL_DATA_KEY,
} from 'src/redux/report/constants';
import { capitalizeWords, validateEmail } from 'src/utils';

interface Props {
  reportPk: number;
  reportIsPublished: boolean;
}

const errorMessages = {
  missing_name: 'Please enter a name',
  missing_email: 'Please enter an email address',
  invalid_email: 'Please enter a valid email address',
};

const ContactInfo: FC<Props> = ({ reportPk, reportIsPublished }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const dispatch = useDispatch();

  const reportDetailData = useSelector(
    (state: RootStateOrAny) => state.viewData[REPORT_DETAIL_DATA_KEY]?.data,
  );

  const sectionDetailData = useSelector(
    (state: RootStateOrAny) => state.viewData[SECTION_DETAIL_DATA_KEY]?.data,
  );

  useEffect(() => {
    if (name === null || name === '') {
      setNameError('missing_name');
    } else {
      setNameError('');
    }
  }, [name]);

  useEffect(() => {
    if (email === null || email === '') {
      setEmailError('missing_email');
    } else if (!validateEmail(email)) {
      setEmailError('invalid_email');
    } else {
      setEmailError('');
    }
  }, [email]);

  function initState(gtdOffices = null, contactName = '', contactEmail = '') {
    if (gtdOffices && !contactName) {
      const { full_name = '', email = '' } = gtdOffices;

      if (full_name && email) {
        setName(full_name);
        setEmail(email);
      }
    } else {
      setName(contactName === null ? '' : contactName);
      setEmail(contactEmail === null ? '' : contactEmail);
    }
  }

  function resetUIState() {
    setIsEdit(false);
    setEmailError('');
    setNameError('');
    setIsSubmitted(false);
  }

  useEffect(() => {
    initState(
      sectionDetailData?.datasets?.gtd_offices,
      reportDetailData?.contact_name,
      reportDetailData?.contact_email,
    );
  }, [
    sectionDetailData?.datasets?.gtd_offices,
    reportDetailData?.contact_name,
    reportDetailData?.contact_email,
  ]);

  function handleSubmit() {
    setIsSubmitted(true);

    if (emailError !== '' || nameError !== '') return;

    const data = {
      contact_name: capitalizeWords(name),
      contact_email: email,
      country_director_name: reportDetailData.country_director_name || '',
    };

    dispatch(
      postAcrInfoBox({
        data,
        url_format: {
          _id_: reportPk,
        },
      }),
    );

    resetUIState();
  }

  function handleCancel() {
    resetUIState();
    initState(
      sectionDetailData?.datasets?.gtd_offices,
      reportDetailData?.contact_name,
      reportDetailData?.contact_email,
    );
  }

  return (
    <Module
      data-test-id="contact-info-section"
      className="contact-info-wrapper"
    >
      <ModuleHeader>
        Contact Info
        <div>
          {!isEdit &&
            !reportIsPublished &&
            sectionDetailData?.permissions?.can_edit_contact_info && (
              <Button
                onClick={() => setIsEdit(true)}
                kind="primary"
                data-test-id="edit-contact-info-button"
              >
                Edit
              </Button>
            )}
          {isEdit && (
            <Button
              onClick={handleCancel}
              kind="secondary"
              data-test-id="close-button"
            >
              Cancel
            </Button>
          )}
          {isEdit && <Button onClick={handleSubmit}>Save Contact Info</Button>}
        </div>
      </ModuleHeader>
      <ModuleBody className={`widget-body ${isEdit ? 'is-edit' : ''}`}>
        {isEdit && (
          <TextInput
            onChange={(e: any) => setName(e.target.value)}
            value={name}
            id="contact-info-name-field"
            labelText="Contact Name"
            invalid={isSubmitted && nameError !== ''}
            invalidText={nameError !== '' ? errorMessages[nameError] : ''}
            placeholder="Insert name"
          />
        )}
        {!isEdit && name && (
          <div data-test-id="contact-name-fields">
            <div className="strong-label">Contact Name</div>
            <Text>{name}</Text>
          </div>
        )}
        {isEdit && (
          <TextInput
            type="email"
            onChange={(e: any) => setEmail(e.target.value)}
            value={email}
            id="contact-info-email-field"
            labelText="Contact Email"
            invalid={isSubmitted && emailError !== ''}
            invalidText={emailError !== '' ? errorMessages[emailError] : ''}
            placeholder="Insert email"
          />
        )}
        {!isEdit && email && (
          <div data-test-id="contact-email-fields">
            <Text className="strong-label">Contact Email</Text>
            <Text>{email}</Text>
          </div>
        )}
        {!isEdit && !name && !email && (
          <div className="no-data-wrapper">No contact info</div>
        )}
      </ModuleBody>
    </Module>
  );
};

export default ContactInfo;
