import { Loading, Text } from '@wfp/ui';
import React, { FC, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import { ACR_ISSUES_OVERVIEW_URL } from 'src/redux/report/constants';
import { IIssuesOverviewActiveFilters, IIssuesOverviewData } from 'src/types';
import IssuesDownloadDocButton from './IssuesDownloadDocButton';
import IssuesOverviewContent from './IssuesOverviewContent';
import IssuesOverviewFilters from './IssuesOverviewFilters';
import IssuesOverviewSidebar from './IssuesOverviewSidebar';

const IssuesOverviewTemplate: FC = () => {
  const issuesOverviewData: IIssuesOverviewData = useSelector(
    (state: RootStateOrAny) => state.viewData[ACR_ISSUES_OVERVIEW_URL]?.data,
  );

  const [activeFilters, setActiveFilters] = useState({
    showNarratives: true,
    showOpenComments: false,
    showImportantComments: false,
    showMyComments: false,
  });

  function toggleActiveFilters(filterBy: keyof IIssuesOverviewActiveFilters) {
    setActiveFilters({
      ...activeFilters,
      [filterBy]: !activeFilters[filterBy],
    });
  }

  return (
    <div className="issues-overview-wrapper">
      <div className="issues-overview-header main-side-padding">
        <Text kind="h1">Narratives and comments</Text>
        <IssuesDownloadDocButton
          period={issuesOverviewData?.period}
          projectCode={issuesOverviewData?.project_code}
          gaPosition="comments_page"
        />
      </div>
      {!issuesOverviewData && (
        <div className="section-loader-wrapper">
          <Loading className="section-loader" withOverlay={false} active />
        </div>
      )}
      {issuesOverviewData && (
        <div className="issues-overview-wrapper-main">
          <IssuesOverviewSidebar sections={issuesOverviewData?.sections} />
          <IssuesOverviewContent activeFilters={activeFilters} />
          <IssuesOverviewFilters
            activeFilters={activeFilters}
            toggleActiveFilters={toggleActiveFilters}
          />
        </div>
      )}
    </div>
  );
};

export default IssuesOverviewTemplate;
