import {
  iconDelete,
  iconMaximize,
  iconMinimize,
  iconOverflowMenu,
} from '@wfp/icons';
import { Icon } from '@wfp/ui';
import React, { useRef } from 'react';
import Dropdown, {
  DropdownContent,
  DropdownTrigger,
} from 'react-simple-dropdown';

const ReviewIcon = ({
  permissions,
  collapsed,
  closed,
  user,
  activeUser,
  data,
  toggleCollapsed,
  toggleDeleteConfirmationModal,
  isReportPublished,
}) => {
  const dpRef = useRef(null);

  function checkIssueAnotherUserReplies() {
    const { comments } = data || {};
    let hasAnotherUserReplies = false;

    if (comments)
      comments.forEach(item => {
        if (item.user.username !== activeUser) hasAnotherUserReplies = true;
      });

    return hasAnotherUserReplies;
  }

  const { delete_issue, delete_own_issue } = permissions;
  const displayDeleteIcon =
    !closed &&
    ((!!delete_own_issue && user.username === activeUser) || !!delete_issue) &&
    (!checkIssueAnotherUserReplies() || !!delete_issue);
  const deleteIcon = displayDeleteIcon && (
    <Icon
      description="Delete"
      fill="black"
      icon={iconDelete}
      id={data.pk}
      className="noselect"
    />
  );

  const collapse_icon = (
    <Icon
      fill="grey"
      description="Thread"
      icon={collapsed ? iconMaximize : iconMinimize}
      className="noselect"
    />
  );
  if (!displayDeleteIcon) return null;

  return (
    <Dropdown ref={dpRef}>
      <DropdownTrigger>
        <Icon
          width="25"
          height="25"
          className="acr-comment-dropdown-menu"
          description="Actions"
          icon={iconOverflowMenu}
          fill="black"
        />
      </DropdownTrigger>
      <DropdownContent>
        <div>
          {displayDeleteIcon && !isReportPublished && (
            <div
              onClick={toggleDeleteConfirmationModal}
              className="acr-comments-action"
            >
              {deleteIcon} Delete Comment
            </div>
          )}
          {!closed && (
            <div
              onClick={() => {
                dpRef.current.hide();
                toggleCollapsed();
              }}
              className="acr-comments-action"
            >
              {collapse_icon} {collapsed ? 'Expand' : 'Collapse'} Comment
            </div>
          )}
        </div>
      </DropdownContent>
    </Dropdown>
  );
};

export default ReviewIcon;
