import { Button, TextArea, TextInput } from '@wfp/ui';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  commentsErrorsMessages,
  maxCommentTitleCharacters,
  SUCCESS_REQUEST,
  UPDATE,
} from 'src/constants';
import {
  requestAPI,
  requestAPIHoldingData,
  sendSignalsAction,
} from 'src/redux/actions';
import { changeViewStateAction } from 'src/redux/report/actions';
import {
  COMMENTS_LIST,
  REPORT_DETAIL_DATA_KEY,
} from 'src/redux/report/constants';
import { sendGAEvent } from 'src/utils';

export const CommentForm = ({
  sectionAPIKwargs,
  commentsContainerID,
  draftComment,
  toggleFormDisplay,
}) => {
  const [title, setTitle] = useState(draftComment?.title || '');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [content, setContent] = useState('');
  const [anchor] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (draftComment?.title) setTitle(draftComment.title);
  }, [draftComment?.title]);

  const resetFields = () => {
    setTitle('');
    setContent('');
  };

  const formErrors = useMemo(() => {
    const errors = {};

    if (content.trim() === '') {
      errors.content = 'empty';
    }

    if (title.trim() === '') {
      errors.title = 'empty';
    } else if (title.length > maxCommentTitleCharacters) {
      errors.title = 'maxLength';
    }

    return errors;
  }, [title, content]);

  const createComment = () => {
    resetFields();

    // If the comment was generated from narrative then update
    // the draftComment value with title, content and flag isCompleted
    if (draftComment) {
      dispatch(
        changeViewStateAction({
          draftComment: {
            title,
            content,
            anchorID: draftComment?.anchorID,
            selection: draftComment?.selection,
            isCompleted: true,
            isExtra: draftComment?.isExtra,
            narrativeId: draftComment?.narrativeId,
          },
        }),
      );

      return;
    }

    // If the comment is created in place by clicking "New comment" btn
    // perform an API call to add the comment

    toggleFormDisplay();

    const requestData = {
      content,
      title,
      anchor: anchor.html_id,
    };

    const params = {
      url_format: {
        ...sectionAPIKwargs,
      },
      method: 'post',
      data: requestData,
      storage_key: `${COMMENTS_LIST}_created`,
      success_actions: [
        sendSignalsAction({
          [commentsContainerID]: {
            [UPDATE]: true,
            [SUCCESS_REQUEST]: true,
          },
        }),
        requestAPIHoldingData(REPORT_DETAIL_DATA_KEY, null, {
          url_format: { _id_: sectionAPIKwargs?._report_ },
        }),
      ],
    };

    dispatch(requestAPI(COMMENTS_LIST, null, params));
  };

  return (
    <div className="acr-comment-form">
      <TextInput
        className="acr-comment-title rounded-border"
        placeholder="Comment Title"
        id="comment-title"
        light={true}
        onChange={e => setTitle(e.target.value)}
        value={title}
        labelText=""
        invalid={formErrors?.title && isSubmitted}
        invalidText={commentsErrorsMessages['title']?.[formErrors?.title]}
      />
      <TextArea
        className="rounded-border acr-comment-body"
        placeholder="Comment Message"
        data-test-id="comment-message-field"
        id="comment-msg-body"
        onChange={e => setContent(e.target.value)}
        value={content}
        labelText=""
        invalid={formErrors?.content && isSubmitted}
        invalidText={commentsErrorsMessages['content']?.[formErrors?.content]}
      />
      <div className="acr-comment-post-btn">
        <Button
          onClick={() => {
            if (draftComment) {
              dispatch(changeViewStateAction({ draftComment: undefined }));
              return;
            }
            toggleFormDisplay();
          }}
          data-test-id="cancel-comment-button"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!isEmpty(formErrors)) {
              setIsSubmitted(true);
              return;
            }

            if (draftComment) {
              sendGAEvent('post_new_comment_from_narrative_btn_clicked');
            } else {
              sendGAEvent('post_new_comment_from_sidebar_btn_clicked');
            }

            createComment();
          }}
          data-test-id="post-new-comment-button"
        >
          Post New Comment
        </Button>
      </div>
    </div>
  );
};

export default CommentForm;
