import { iconInfoGlyph } from '@wfp/icons';
import {
  Blockquote,
  Button,
  Icon,
  Module,
  ModuleBody,
  ModuleHeader,
  TextInput,
} from '@wfp/ui';
import { Tooltip } from 'acr_ui/sources/components/Tooltip';
import Compress from 'compress.js';
import isEqual from 'lodash/isEqual';
import React, { type FC, useEffect, useMemo, useRef, useState } from 'react';
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Upload from 'src/assets/images/upload-icon.svg';
import ImageCropper from 'src/components/ImageCropper';
import DownloadCoverPreviewButton from 'src/components/ImageUploader/DownloadCoverPreviewButton';
import NoImageBox from 'src/components/ImageUploader/NoImageBox';
import { API_HOST } from 'src/constants';
import { requestAPI, requestAPIHoldingData } from 'src/redux/actions';
import {
  IMAGE_MANAGER_URL,
  REPORT_DETAIL_DATA_KEY,
  SECTION_DETAIL_DATA_KEY,
} from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { formatFileSize, isValidImageUrlFormat } from 'src/utils';

const imageUploaderConfig = {
  cv: {
    title: 'Cover image',
    maxFileSize: 1024 * 300, // 300kB
    hasInfoFields: true,
    supportedFileTypes: ['image/jpeg'],
    landscape: true,
    minWidth: 1200,
    minHeight: 528,
    infoRequired: true,
  },
  xs: {
    title: 'Attached image',
    maxFileSize: 1024 * 1024 * 3, // 3 MB
    hasInfoFields: true,
    supportedFileTypes: ['image/jpeg'],
    landscape: false,
    infoRequired: true,
  },
  mp: {
    title: 'Map image',
    maxFileSize: 1024 * 1024 * 3, // 3 MB
    hasInfoFields: false,
    supportedFileTypes: ['image/jpeg', 'image/png'],
    landscape: false,
    infoRequired: false,
  },
};

const captionCharsLimit = 150;
const creditCharsLimit = 150;

const compress = new Compress();

const ImageUploader: FC = () => {
  const [isEdit, setIsEdit] = useState(false);

  const [photoCredit, setPhotoCredit] = useState('');
  const [photoCaption, setPhotoCaption] = useState('');

  const [uploadError, setUploadError] = useState('');

  const [needsCrop, setNeedsCrop] = useState(false);

  const [uploadedImageSrc, setUploadedImageSrc] = useState('');
  const [uploadedImageData, setUploadedImageData] = useState(null);
  const [finalImageSrc, setFinalImageSrc] = useState('');
  const [finalImageData, setFinalImageData] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [deleteImage, setDeleteImage] = useState(false);

  const viewState = useSelector((state: RootStateOrAny) => state.viewState);
  const reportDetailData = useSelector(
    (state: RootStateOrAny) => state.viewData[REPORT_DETAIL_DATA_KEY]?.data,
  );
  const sectionDetailData = useSelector(
    (state: RootStateOrAny) => state.viewData?.[SECTION_DETAIL_DATA_KEY]?.data,
  );
  const imageAttachmentType = sectionDetailData?.image_attachment_type;
  const isFetchingAttachmentTypeData = useSelector(
    (state: RootStateOrAny) =>
      state.viewData[`${IMAGE_MANAGER_URL}_${imageAttachmentType}`]?.isFecthing,
  );
  const attachmentTypeData = useSelector(
    (state: RootStateOrAny) =>
      state.viewData[`${IMAGE_MANAGER_URL}_${imageAttachmentType}`]?.data,
  );

  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<any>();

  const dispatch = useDispatch();

  const activeSectionID = viewState?.activeSectionID;
  const reportId = reportDetailData?.pk;
  const suggestedName = `${reportDetailData?.project_code}_${reportDetailData?.period}`;

  const invalidCaptionCharsLimit = photoCaption.length > captionCharsLimit;
  const emptyCaption =
    imageUploaderConfig?.[imageAttachmentType]?.infoRequired &&
    photoCaption?.trim() === '';
  const invalidCreditCharsLimit = photoCredit.length > creditCharsLimit;
  const emptyCredit =
    imageUploaderConfig?.[imageAttachmentType]?.infoRequired &&
    photoCredit?.trim() === '';

  const InputUploadFile = () => (
    <input
      ref={fileInputRef}
      name="image_field"
      hidden
      onChange={handleFileInputChange}
      id="image_field"
      type="file"
      accept={imageUploaderConfig?.[imageAttachmentType]?.supportedFileTypes}
    />
  );

  const isEditable = useMemo(() => {
    if (!attachmentTypeData || !imageAttachmentType || !reportDetailData)
      return false;

    if (reportDetailData?.state === 'Published') return false;

    if (imageAttachmentType === 'xs') {
      return reportDetailData?.permissions
        ?.has_permission_to_add_image_extra_section;
    }

    return attachmentTypeData?.can_edit;
  }, [attachmentTypeData, imageAttachmentType, reportDetailData]);

  const imageUploadInfo = useMemo(() => {
    const supportedFileTypes =
      imageUploaderConfig?.[imageAttachmentType]?.supportedFileTypes;

    if (!supportedFileTypes) return '';

    const maxFileSize = imageUploaderConfig?.[imageAttachmentType]?.maxFileSize;

    const hasLandscape = imageUploaderConfig?.[imageAttachmentType]?.landscape;
    const minWidth = imageUploaderConfig?.[imageAttachmentType]?.minWidth;
    const minHeight = imageUploaderConfig?.[imageAttachmentType]?.minHeight;

    const formattedSize = formatFileSize(maxFileSize);

    const listTypes = supportedFileTypes
      ?.map((item: string) => item.replace('image/', '').toUpperCase())
      .join(', ');

    const infoList = [
      `The format of the image must be ${listTypes}.`,
      `The size of the file must be under ${formattedSize}.`,
    ];

    if (hasLandscape) {
      infoList.push('Image has to be of landscape orientation.');
    }
    if (minWidth) {
      infoList.push(`Image must have a width of at least ${minWidth} px.`);
    }
    if (minHeight) {
      infoList.push(`Image must have a height of at least ${minHeight} px.`);
    }

    return infoList.join('<br/>');
  }, [attachmentTypeData]);

  useEffect(() => {
    if (attachmentTypeData?.photo_credit) {
      setPhotoCredit(attachmentTypeData.photo_credit);
    } else {
      setPhotoCredit('');
    }
    if (attachmentTypeData?.caption) {
      setPhotoCaption(attachmentTypeData.caption);
    } else {
      setPhotoCaption('');
    }
    if (attachmentTypeData?.image_field) {
      setFinalImageSrc(attachmentTypeData?.image_field);
    } else {
      setFinalImageSrc('');
    }

    setIsSubmitted(false);
  }, [attachmentTypeData, isEdit]);

  useEffect(() => {
    if (!imageAttachmentType || !reportId) return;

    dispatch(
      requestAPI(IMAGE_MANAGER_URL, null, {
        url_format: { _id_: reportId, _type_: imageAttachmentType },
        storage_key: `${IMAGE_MANAGER_URL}_${imageAttachmentType}`,
      }),
    );
  }, [requestAPI, reportId, imageAttachmentType]);

  function handleRemoveImage() {
    setFinalImageSrc('');
    setUploadedImageSrc('');
    setUploadedImageData(null);
    setFinalImageData(null);
    setUploadError('');
    setDeleteImage(true);
  }

  function handleCancel() {
    setUploadedImageSrc('');
    setUploadedImageData(null);
    setFinalImageData(null);
    setUploadError('');

    // Reset data from BE
    if (attachmentTypeData?.photo_credit) {
      setPhotoCredit(attachmentTypeData.photo_credit);
    } else {
      setPhotoCredit('');
    }
    if (attachmentTypeData?.caption) {
      setPhotoCaption(attachmentTypeData.caption);
    } else {
      setPhotoCaption('');
    }
    if (attachmentTypeData?.image_field) {
      setFinalImageSrc(attachmentTypeData?.image_field);
    } else {
      setFinalImageSrc('');
    }

    setDeleteImage(false);
    setIsEdit(false);
  }

  function handleCancelCrop() {
    setUploadError('');
    setUploadedImageSrc('');
    setUploadedImageData(null);
    setNeedsCrop(false);
  }

  function handleSave() {
    setIsSubmitted(true);

    if (formHasErrors) return;

    const url = `/api/image-manager/${reportId}/${imageAttachmentType}`;

    const form = new FormData();
    form.append('photo_credit', photoCredit);
    form.append('caption', photoCaption);

    if (deleteImage) {
      form.append('image_field', '');
    } else if (finalImageData) {
      form.append('image_field', finalImageData);
    }

    apiCallAsync(`${API_HOST}${url}`, form, 'put').then(() => {
      dispatch(
        requestAPIHoldingData(SECTION_DETAIL_DATA_KEY, null, {
          url_format: { _id_: activeSectionID },
        }),
      );

      dispatch(
        requestAPIHoldingData(IMAGE_MANAGER_URL, null, {
          url_format: { _id_: reportId, _type_: imageAttachmentType },
          storage_key: `${IMAGE_MANAGER_URL}_${imageAttachmentType}`,
        }),
      );

      setUploadError('');
      setIsEdit(false);
    });
  }

  function handleFileInputChange() {
    setUploadError('');

    if (fileInputRef.current.files.length <= 0) {
      return;
    }

    const file = fileInputRef.current.files[0];

    let newName = imageAttachmentType + '_' + suggestedName;
    if (file.type.includes('jpeg')) {
      newName += '.jpg';
    } else {
      newName += '.png';
    }
    const myNewFile = new File([file], newName, { type: file.type });
    const maxFileSize = imageUploaderConfig?.[imageAttachmentType]?.maxFileSize;

    // If file size exceeds limits cancel the upload process
    if (file.size > maxFileSize) {
      setUploadError(
        `The file is too large 
      (${formatFileSize(file.size)}). 
      Please choose a file under ${formatFileSize(maxFileSize)}`,
      );

      return;
    }

    setUploadedImageData(myNewFile);
    setUploadedImageSrc(window.URL.createObjectURL(file));
  }

  async function handleImageSizeValidation() {
    const realX = imageRef.current.naturalWidth;
    const realY = imageRef.current.naturalHeight;

    const minWidth = imageUploaderConfig?.[imageAttachmentType]?.minWidth;
    const minHeight = imageUploaderConfig?.[imageAttachmentType]?.minHeight;

    // If image width is below minWidth cancel the process
    if (minWidth && realX < minWidth) {
      setUploadError(
        `The image width is too small. Please choose a file with a width of at least ${minWidth} px`,
      );

      setUploadedImageData(null);
      setUploadedImageSrc('');

      return;
    }

    // If image height is below minHeight cancel the process
    if (minHeight && realY < minHeight) {
      setUploadError(
        `The image height is too small. Please choose a file with a height of at least ${minHeight} px`,
      );

      setUploadedImageData(null);
      setUploadedImageSrc('');

      return;
    }

    setDeleteImage(false);

    let maxWidth,
      maxHeight = null;
    if (minWidth / minHeight < realX / realY) {
      maxHeight = minHeight;
    } else {
      maxWidth = minWidth;
    }

    const image = await fetch(uploadedImageSrc)
      .then(res => res.blob())
      .then(blob => blob);

    let options: any = {
      size: 0.3, // the max size in MB, defaults to 2MB
      resize: true,
    };
    if (maxWidth) {
      options = {
        ...options,
        maxWidth, // the max width of the output image, defaults to 1920px
      };
    } else {
      options = {
        ...options,
        maxHeight, // the max height of the output image, defaults to 1920px        resize: true,
      };
    }

    const compressedImage = await compress
      .compress([image], options)
      .then(data => {
        return data[0]; // returns an array of compressed images
      });

    setUploadedImageSrc(`${compressedImage.prefix}${compressedImage.data}`);

    if (
      imageAttachmentType === 'cv' &&
      !isEqual([realX, realY], [minWidth, minHeight])
    ) {
      setNeedsCrop(true);
    } else {
      setUploadedImageSrc('');
      setFinalImageSrc(uploadedImageSrc);
      setFinalImageData(uploadedImageData);
    }
  }

  function changeFinalImage(newFileUrl: string, newFile: Blob) {
    setNeedsCrop(false);

    setUploadError('');
    setFinalImageData(newFile);
    setFinalImageSrc(newFileUrl);
    setUploadedImageSrc('');
    setUploadedImageData(null);
  }

  if (isFetchingAttachmentTypeData || !attachmentTypeData) return <></>;

  function getFinalImageSrc() {
    if (
      isValidImageUrlFormat(finalImageSrc) &&
      !finalImageSrc.includes('blob')
    ) {
      return `${finalImageSrc}?${uuidv4()}`;
    }

    return finalImageSrc;
  }

  function getCaptionErrorMsg() {
    if (invalidCaptionCharsLimit) {
      return `Image Caption can be maximum ${captionCharsLimit} characters long`;
    }
    if (emptyCaption) {
      return `Image Caption is required`;
    }
    return '';
  }

  function getCreditErrorMsg() {
    if (invalidCreditCharsLimit) {
      return `Image Credit can be maximum ${creditCharsLimit} characters long`;
    }
    if (emptyCredit) {
      return `Image Credit is required`;
    }
    return '';
  }

  const formHasErrors =
    invalidCaptionCharsLimit ||
    invalidCreditCharsLimit ||
    emptyCaption ||
    emptyCredit;

  return (
    <Module className="image-uploader">
      <ModuleHeader>
        <div className="image-uploader-title">
          {imageUploaderConfig?.[imageAttachmentType].title}
          {imageAttachmentType === 'cv' && (
            <Tooltip
              title={
                <>
                  <p>The format of the image must be JPEG.</p>
                  <p>The size of the file must be under 300 kB.</p>
                  <p>Image has to be of landscape orientation.</p>
                  <p>Image must have a width of at least 1200 px.</p>
                  <p>Image must have a height of at least 528 px.</p>
                </>
              }
              trigger="mouseenter"
              theme="light cover-image-info"
              position="bottom"
            >
              <Icon
                className="word-limit-panel-icon-info"
                width="24"
                height="24"
                fill="#0b77c2"
                icon={iconInfoGlyph}
                description=""
                data-test-id="information-icon"
              />
            </Tooltip>
          )}
        </div>
        <div>
          {!isEdit &&
            imageAttachmentType === 'cv' &&
            attachmentTypeData?.image_field && (
              <DownloadCoverPreviewButton reportId={reportId} />
            )}
          {!isEdit && isEditable && (
            <Button
              onClick={() => setIsEdit(true)}
              kind="primary"
              data-test-id="edit-button"
            >
              Edit
            </Button>
          )}
          {isEdit && !needsCrop && (
            <Button
              onClick={handleCancel}
              kind="secondary"
              data-test-id="cancel-button"
            >
              Cancel
            </Button>
          )}
          {isEdit && !needsCrop && (
            <Button onClick={handleSave} data-test-id="save-button">
              Save
            </Button>
          )}
        </div>
      </ModuleHeader>
      <ModuleBody className={`widget-body ${isEdit ? 'is-edit' : ''}`}>
        {!isEdit && (
          <>
            {attachmentTypeData?.image_field ? (
              <a
                href={`${attachmentTypeData?.image_field}?${uuidv4()}`}
                target="_blank"
                className="image-read-only-wrapper"
                rel="noreferrer"
              >
                <img
                  className="image-read-only"
                  src={`${attachmentTypeData?.image_field}?${uuidv4()}`}
                />
              </a>
            ) : (
              <NoImageBox />
            )}
            <div className="image-uploader-info-fields">
              {attachmentTypeData?.photo_credit && (
                <div>
                  <span
                    className="image-uploader-label"
                    data-test-id="image-credit-view-title"
                  >
                    Image Credit:
                  </span>{' '}
                  {attachmentTypeData?.photo_credit}
                </div>
              )}
              {attachmentTypeData?.caption && (
                <div>
                  <span
                    className="image-uploader-label"
                    data-test-id="image-caption-view-title"
                  >
                    Image Caption:
                  </span>{' '}
                  {attachmentTypeData?.caption}
                </div>
              )}
            </div>
          </>
        )}
        {isEdit && (
          <>
            {uploadedImageSrc || finalImageSrc ? (
              <>
                {!needsCrop && (
                  <div className="image-uploader-edit-mode">
                    {finalImageSrc ? (
                      <img
                        className="image-read-only final"
                        src={getFinalImageSrc()}
                      />
                    ) : (
                      <img
                        className="image-read-only uploaded"
                        src={uploadedImageSrc}
                      />
                    )}
                    <div className="image-uploader-edit-image-btns">
                      <label>
                        <div
                          className="image-uploader-edit-btn"
                          data-test-id="change-image-label"
                        >
                          Change image
                        </div>
                        <InputUploadFile />
                      </label>
                      <div
                        className="image-uploader-edit-btn red"
                        onClick={handleRemoveImage}
                        data-test-id="remove-image-label"
                      >
                        Remove image
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <label className="upload-image-input-box">
                <div>
                  <img src={Upload} />
                  <p className="click-upload-image-btn">
                    Click to upload image
                  </p>
                  <div className="image-upload-info-list">
                    <div
                      dangerouslySetInnerHTML={{ __html: imageUploadInfo }}
                    />
                  </div>
                </div>
                <InputUploadFile />
              </label>
            )}
            {uploadError && (
              <Blockquote
                kind="error"
                withIcon
                className="image-uploader-error"
              >
                {uploadError}
              </Blockquote>
            )}
            {uploadedImageSrc && (
              <img
                className="preview-img"
                src={uploadedImageSrc}
                onLoad={handleImageSizeValidation}
                ref={imageRef}
              />
            )}
            {needsCrop && (
              <ImageCropper
                imgSrc={uploadedImageSrc}
                width={imageUploaderConfig?.[imageAttachmentType]?.minWidth}
                height={imageUploaderConfig?.[imageAttachmentType]?.minHeight}
                changeFinalImage={changeFinalImage}
                suggestedName={`${imageAttachmentType}_${suggestedName}`}
                handleCancelCrop={handleCancelCrop}
              />
            )}
            {!needsCrop && (
              <>
                <TextInput
                  onChange={(e: any) => setPhotoCredit(e.target.value)}
                  value={photoCredit}
                  labelText="Image Credit"
                  invalid={
                    isSubmitted && (invalidCreditCharsLimit || emptyCredit)
                  }
                  invalidText={getCreditErrorMsg()}
                  placeholder=""
                  data-test-id="image-credit-input"
                />
                {(!isSubmitted ||
                  (isSubmitted &&
                    !invalidCreditCharsLimit &&
                    !emptyCredit)) && (
                  <p className="invalid-credit-image-uploader">{`Maximum ${creditCharsLimit} characters`}</p>
                )}
                <br />
                <TextInput
                  onChange={(e: any) => setPhotoCaption(e.target.value)}
                  value={photoCaption}
                  formItemClassName="image-uploader-caption-input"
                  labelText="Image Caption"
                  invalid={
                    isSubmitted && (invalidCaptionCharsLimit || emptyCaption)
                  }
                  invalidText={getCaptionErrorMsg()}
                  placeholder=""
                  data-test-id="image-caption-input"
                />
                {(!isSubmitted ||
                  (isSubmitted &&
                    !invalidCaptionCharsLimit &&
                    !emptyCaption)) && (
                  <p className="invalid-caption-image-uploader">{`Maximum ${captionCharsLimit} characters`}</p>
                )}
              </>
            )}
          </>
        )}
      </ModuleBody>
    </Module>
  );
};

export default ImageUploader;
