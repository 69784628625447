import { iconAddGlyph, iconHeaderClose } from '@wfp/icons';
import { Button, Icon } from '@wfp/ui';
import { isEmpty } from 'lodash/lang';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generatePath, NavLink } from 'react-router-dom';

import { DisplayToggleWrapper, SHOW } from 'src/components/DisplayWrapper';
import IssuesDownloadDocButton from 'src/components/IssuesOverviewTemplate/IssuesDownloadDocButton';
import { CRF23 } from 'src/constants';
import { SUCCESS_REQUEST, UPDATE } from 'src/constants';
import { cleanSignalsAction, requestAPI } from 'src/redux/actions';
import {
  COMMENTS_LIST,
  COMMENTS_TARGET_ID,
  REPORT_DETAIL_DATA_KEY,
  SECTION_DETAIL_DATA_KEY,
} from 'src/redux/report/constants';
import CommentForm from './CommentForm';
import CommentList from './CommentList';

export class CommentPanelComponent extends Component {
  state = {
    collapseForm: false,
  };

  componentDidMount() {
    this.updateCommentsList();
  }

  componentWillUnmount() {
    this.props.cleanSignals({ [COMMENTS_TARGET_ID]: [SHOW] });
  }

  getSectionAPIKwargs = () => {
    return {
      _office_: this.props.officePk,
      _report_: this.props.reportPk,
      _section_: this.props.sectionPk,
    };
  };

  updateCommentsList = () => {
    const sectionAPIKwargs = this.getSectionAPIKwargs();

    if (
      !sectionAPIKwargs?._office_ ||
      !sectionAPIKwargs?._report_ ||
      !sectionAPIKwargs?._section_
    ) {
      return;
    }

    this.props.fetchCommentsRelatedToSection({
      url_format: sectionAPIKwargs,
      storage_key: COMMENTS_LIST,
    });
  };

  componentDidUpdate(prevProps) {
    if (!!this.props.signals[UPDATE] && !prevProps.signals[UPDATE]) {
      this.updateCommentsList();
      this.props.cleanSignals({ [this.props.id]: [UPDATE, SUCCESS_REQUEST] });
    }

    if (this.props.sectionPk !== prevProps.sectionPk) {
      this.updateCommentsList();
    }
  }

  toggleFormDisplay = () => {
    this.setState(state => {
      return { collapseForm: !state.collapseForm };
    });
  };

  render() {
    try {
      const comments = !isEmpty(this.props.data) ? this.props.data : [];
      const sectionAPIKwargs = this.getSectionAPIKwargs();
      const { add_issue, ...rest } = this.props.permissions;
      const { anchors = [] } = this.props;
      return (
        <div
          className="acr-comments-panel"
          data-test-id="comments-panel"
          id="acr-comments-wrapper"
        >
          <DisplayToggleWrapper
            targetId={COMMENTS_TARGET_ID}
            component={Button}
            className="comments-close"
            title="Close comments"
          >
            &nbsp;
            <Icon
              icon={iconHeaderClose}
              description="Close comments"
              {...{
                width: '2em',
                height: '1.5em',
                fill: 'black',
              }}
            />
          </DisplayToggleWrapper>
          <h3
            className="comments-panel-title"
            data-test-id="comments-panel-title"
          >
            Comments
            {add_issue && !this.props.reportIsPublished ? (
              <Button
                className="add-comment-button"
                data-test-id="new-comment-button"
                onClick={this.toggleFormDisplay}
                disabled={!!this.props.draftComment}
              >
                <Icon
                  icon={iconAddGlyph}
                  description="Add new comment"
                  fill="white"
                />
                <span className="add-comment-button-title">New Comment</span>
              </Button>
            ) : (
              ''
            )}
          </h3>
          <div style={{ display: 'flex', width: '100%', paddingRight: '10px' }}>
            <div className="issues-overview-link-wrapper">
              <NavLink
                to={generatePath(
                  this.props.routesConfig.root.sub_routes.all_comments.path,
                  {
                    year: this.props.period,
                    cpb: this.props.project_code,
                  },
                )}
                target="_blank"
                className="issues-overview-link"
                data-test-id="narrative-and-comments-link"
              >
                {this.props.is2023CRF
                  ? 'View all narratives and comments'
                  : 'View narrative and comments'}
              </NavLink>
            </div>
            <IssuesDownloadDocButton
              period={this.props.period}
              projectCode={this.props.project_code}
              gaPosition="sidebar"
              sectionId={this.props.sectionPk}
            />
          </div>

          {this.state.collapseForm && (
            <CommentForm
              toggleFormDisplay={this.toggleFormDisplay}
              sectionAPIKwargs={sectionAPIKwargs}
              anchors={anchors}
              commentsContainerID={this.props.id}
              cleanForm={this.props.signals[SUCCESS_REQUEST]}
            />
          )}

          {this.props?.draftComment && (
            <CommentForm
              sectionAPIKwargs={sectionAPIKwargs}
              anchors={anchors}
              commentsContainerID={this.props.id}
              cleanForm={this.props.signals[SUCCESS_REQUEST]}
              draftComment={this.props?.draftComment}
            />
          )}

          <CommentList
            sectionAPIKwargs={sectionAPIKwargs}
            commentsContainerID={this.props.id}
            comments={comments}
            user={this.props.user}
            permissions={rest}
            deleteComment={this.deleteComment}
          />
        </div>
      );
    } catch (e) {
      console.log('Error', e);
      return null;
    }
  }
}

export const mapStateToProps = (state, ownProps) => {
  let props = {};

  const draftComment = !state.viewState?.draftComment?.isCompleted
    ? state.viewState?.draftComment
    : undefined;

  try {
    props = {
      anchors: state.viewData[SECTION_DETAIL_DATA_KEY].data?.anchors,
      reportPk: state.viewData[SECTION_DETAIL_DATA_KEY].data?.report.id,
      officePk:
        state.viewData[SECTION_DETAIL_DATA_KEY]?.data?.report?.office_code,
      sectionPk: state.viewData[SECTION_DETAIL_DATA_KEY].data?.id,
      reportIsPublished:
        state.viewData[REPORT_DETAIL_DATA_KEY]?.data?.state === 'Published',
      permissions:
        state.viewData[REPORT_DETAIL_DATA_KEY].data?.permissions
          ?.comment_permissions,
      user: state.viewData[REPORT_DETAIL_DATA_KEY].data?.username,
      period: state.viewData[REPORT_DETAIL_DATA_KEY].data?.period,
      project_code: state.viewData[REPORT_DETAIL_DATA_KEY].data?.project_code,
      is2023CRF: state.viewData.ACR_REPORTS_DETAIL?.data?.crf === CRF23,
      draftComment,
    };
  } catch (e) {
    console.log('Error', e);
  }
  return {
    routesConfig: state.routesConfig,
    data: {},
    isFetching: true,
    error: null,
    ...state.viewData[COMMENTS_LIST],
    signals: {
      ...state.component_signals[ownProps.id],
    },
    ...props,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    fetchCommentsRelatedToSection: (params = {}) => {
      dispatch(requestAPI(COMMENTS_LIST, null, params));
    },
    cleanSignals: signalsToDrop => {
      dispatch(cleanSignalsAction(signalsToDrop));
    },
  };
};

const CommentPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommentPanelComponent);

export default CommentPanel;
