import {
  Button,
  Module,
  ModuleBody,
  ModuleFooter,
  ModuleHeader,
} from '@wfp/ui';
import { getBeneficiariesByProgrammeAreaOptions } from 'acr_ui/sources/components/dataPresentation/actualVsPlannedAdjustments';
import { getReduxConnectedViewModeOptions as getAnnualCBTCommodityVoucherDistributionViewModeOptions } from 'acr_ui/sources/components/dataPresentation/annualCBTCommodityVoucherDistribution';
import { getReduxConnectedViewModeOptions as getAnnualFoodDistributionViewModeOptions } from 'acr_ui/sources/components/dataPresentation/annualFoodDistribution';
import { BeneficiariesByAgeGroupComponent } from 'acr_ui/sources/components/dataPresentation/beneficiariesByAgeGroup';
import { BeneficiariesByResidenceStatusComponent } from 'acr_ui/sources/components/dataPresentation/beneficiariesByResidenceStatus';
import { CrossCuttingTable } from 'acr_ui/sources/components/dataPresentation/crosscuttingtables';
import CSPFinancialOverview from 'acr_ui/sources/components/dataPresentation/cspFinancialOverview';
import { GaMTable } from 'acr_ui/sources/components/dataPresentation/gamTable';
import { OutputsOutcomesTable } from 'acr_ui/sources/components/dataPresentation/outputsOutcomesTable';
import { R2RTable } from 'acr_ui/sources/components/dataPresentation/r2rTable';
import { TotalBeneficiariesComponent } from 'acr_ui/sources/components/dataPresentation/totalBeneficiaries';
import { Infographics } from 'acr_ui/sources/components/Infographics';
import { StyledDataArrayTableComponent } from 'acr_ui/sources/components/tables/styledDataArrayTable';
import { getReduxConnectedViewModeComponent } from 'acr_ui/sources/components/widgets/viewMode';
import get from 'lodash/get';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import NestedParametrizedDataContainer from 'src/components/container/nestedParametrizedData';
import { SHOW } from 'src/components/DisplayWrapper';
import ImageUploader from 'src/components/ImageUploader';
import AnchorModal from 'src/components/ReportTemplate/anchorModal';
import TinyEditorWrapper from 'src/components/ReportTemplate/WorkspaceACR2023/TinyEditorWrapper';
import { TableParametrizedDataPresentationComponent as RowSpanTable } from 'src/components/TableMain/rowspanTable';
import {
  API_HOST,
  narrativeAutosaveInterval,
  SUCCESS_REQUEST,
  UPDATE,
} from 'src/constants';
import {
  clearApiKeys,
  getActionAPISource,
  requestAPI,
  requestAPIHoldingData,
  requestAPILatest,
  sendSignalsAction,
  setExtraNarrativeVal,
  setNarrativeVal,
  toggleSignalsAction,
} from 'src/redux/actions';
import {
  cancelNarrativeEditing,
  changeContentMode,
  changeViewStateAction,
  getIconsDetails,
  LockInfographicsAction,
  lockNarrative,
  selectSectionsDataSource,
  UnlockInfographicsAction,
  UpdateInfographicsAction,
  updateNarrativeValue,
} from 'src/redux/report/actions';
import {
  COMMENTS_LIST,
  COMMENTS_TARGET_ID,
  ICONS_DETAILS_KEY,
  NARRATIVE,
  NARRATIVE_UNLOCK_AND_FORCE_POST_DATA_KEY,
  NARRATIVE_UPDATE_DATA_KEY,
  REPORT_DETAIL_DATA_KEY,
  SECTION_DETAIL_DATA_KEY,
  SECTION_REFRESH_DATA_SOURCE_KEY,
  VERSIONS,
  WORDCOUNT_CONFIG,
} from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { getReportPageTitle } from 'src/utils';
import { sendGAEvent } from 'src/utils';
import {
  buildErrorComponents,
  countWords,
  getLocalStorageItem,
  setLocalStorageItem,
  showNotification,
} from 'src/utils';
import BeneficiariesByModality from './BeneficiariesByModality';
import BottomLinks from './BottomLinks';
import { DATA_KEY_ON_DATA_SET, MODE_TITLE_MAP } from './config';
import ContactInfo from './ContactInfo';
import DataNotes from './DataNotes';
import ExtraNarratives from './ExtraNarratives';
import LatestRefreshInfo from './LatestRefreshInfo';
import SectionControls from './SectionControls';
import Title from './Title';
import TotalBenByModWrapper from './TotalBenByModWrapper';
import TotalFoodAndCBTWrapper from './TotalFoodAndCBTWrapper';
import VersionsTab from './VersionsTab';
import WordLimitPanel from './WordLimitPanel';

export const StrategicOutcomeTitle = props => (
  <span>{props.data.strategic_outcome}</span>
);

const ViewMode = getReduxConnectedViewModeComponent(connect);

export const DATA_SOURCES_WIDGETS_MAP = {
  // Summary
  'Total Beneficiaries': TotalBeneficiariesComponent,
  'Beneficiaries by Residence Status': BeneficiariesByResidenceStatusComponent,
  'Beneficiaries by Age Group': BeneficiariesByAgeGroupComponent, // 2018 ACR specific
  'Beneficiaries by Sex and Age Group': BeneficiariesByAgeGroupComponent,
  'Beneficiaries by Programme Area': ViewMode,

  'Beneficiaries by Modality': BeneficiariesByModality,
  'Total Food and CBT': TotalFoodAndCBTWrapper,
  'Total Transfers by Modality': TotalBenByModWrapper,
  'Annual Food Transfer': ViewMode,
  'Annual Food Distribution': ViewMode,
  'Annual Cash Based Transfer and Commodity Voucher': ViewMode,
  'Annual CBT and Commodity Voucher Distribution': ViewMode,
  'WFP contribution to SDGs': StyledDataArrayTableComponent,

  // Strategic Outcome
  'Output Indicators': NestedParametrizedDataContainer,
  'Outcome Indicators': NestedParametrizedDataContainer,
  'Strategic Outcome Title': StrategicOutcomeTitle,
  'Strategic Outcome and Output Results': OutputsOutcomesTable,
  'Annual CSP Financial Overview by Strategic Outcome and Activity':
    CSPFinancialOverview,

  'WFP Gender and Age Marker': GaMTable,
  Resources: R2RTable,

  // Crosscutting results

  'Progress towards Gender Equality indicators': CrossCuttingTable,
  'Protection indicators': CrossCuttingTable,
  'Accountability to Affected Population indicators': CrossCuttingTable,
  'Environment indicators': CrossCuttingTable,
};

// Special locations for some widgets
export const SPECIAL_LOCATION_OF_WIDGETS = {
  header: ['Strategic Outcome Title'],
};

const annualCBTCommodityVoucherDistributionViewModeOptions =
  getAnnualCBTCommodityVoucherDistributionViewModeOptions(connect);

const annualFoodDistributionViewModeOptions =
  getAnnualFoodDistributionViewModeOptions(connect);

const getDataSourceWidgetsParametersMap = {
  // Summary
  'Annual Food Transfer': {
    noModuleTitle: true,
    title: 'Annual Food Transfer',
    id: 'SUMMARY__annual_food_outcome',
    options: annualFoodDistributionViewModeOptions,
  },
  'Beneficiaries by Programme Area': {
    noModuleTitle: true,
    title: 'Beneficiaries by Programme Area',
    id: 'SUMMARY__beneficiaries_by_programme_area',
    options: getBeneficiariesByProgrammeAreaOptions(),
  },
  'Annual Cash Based Transfer and Commodity Voucher': {
    noModuleTitle: true,
    title: 'Annual Cash Based Transfer and Commodity Voucher',
    id: 'SUMMARY__annual_food_cbt',
    options: annualCBTCommodityVoucherDistributionViewModeOptions,
  },
  'Annual Food Distribution': {
    noModuleTitle: true,
    title: 'Annual Food Distribution',
    id: 'SUMMARY__annual_food_outcome',
    options: annualFoodDistributionViewModeOptions,
  },
  'Annual CBT and Commodity Voucher Distribution': {
    noModuleTitle: true,
    title: 'Annual CBT and Commodity Voucher Distribution',
    id: 'SUMMARY__annual_food_cbt',
    options: annualCBTCommodityVoucherDistributionViewModeOptions,
  },
  'WFP contribution to SDGs': {},

  'Strategic Outcome and Output Results': {
    id: 'SO__strategic_outcome_table',
    containerClassName: 'so-table',
  },

  // Strategic Outcome
  'Output Indicators': {
    parametersList: [
      'strategic_result',
      'output',
      'output_category',
      'activity',
      // 'output_indicator',
    ],
    id: 'SO_output_values_table',
    presentationComponent: RowSpanTable,
    presentationComponentParameters: {
      headers: [
        { key: 'output_detailed_indicator', title: 'Detailed Indicator' },
        { key: 'unit_of_measure', title: 'Unit' },
        { key: 'target_value', title: 'Target Value' },
        { key: 'actual_value', title: 'Actual Value' },
        { key: 'percentage_achieved', title: '% Achieved' },
      ],
    },
  },
  'Outcome Indicators': {
    parametersList: [
      // 'strategic_outcome',
      // 'outcome_indicator',
      'strategic_result',
      'target_location',
      'activities',
      'modalities',
    ],
    id: 'SO_output_values_table',
    presentationComponent: RowSpanTable,
    presentationComponentParameters: {
      headers: [
        { key: 'strategic_result', title: 'Strategic Result' },
        { key: 'outcome_indicator', title: 'Outcome Indicator' },
        { key: 'target_location', title: 'Target / Location' },
        { key: 'activities', title: 'Activities' },
        { key: 'modalities', title: 'Modalities' },
        { key: 'gender', title: 'Gender' },
        { key: 'base_value', title: 'Base Value' },
        { key: 'latest_followup', title: 'Latest Follow Up' },
        { key: 'year_end_target', title: 'Year End Target' },
        { key: 'csp_end_target', title: 'CSP End Target' },
      ],
    },
  },

  // Crosscutting results
  'Progress towards Gender Equality indicators': {
    id: 'cross-cutting-gender',
    containerClassName: 'so-table',
  },
  'Protection indicators': {
    id: 'cross-cutting-protection',
    containerClassName: 'so-table',
  },
  'Accountability to Affected Population indicators': {
    id: 'cross-cutting-population',
    containerClassName: 'so-table',
  },
  'Environment indicators': {
    id: 'cross-cutting-environment',
    containerClassName: 'so-table',
  },
};

class WorkspaceContainer extends Component {
  constructor(props) {
    super(props);
    this.updateNarrativeContent = this.updateNarrativeContent.bind(this);
    this.onNarrativeChange = this.onNarrativeChange.bind(this);
    this.unlockAndForcePostNarrative =
      this.unlockAndForcePostNarrative.bind(this);
    this.updateWordsCount = this.updateWordsCount.bind(this);
    this.onChangeExtraNarratives = this.onChangeExtraNarratives.bind(this);
    this.handleAutoSave = this.handleAutoSave.bind(this);
    this.updateExtraNarrativeWordsLimit =
      this.updateExtraNarrativeWordsLimit.bind(this);
    this.handleRefreshStatusCheck = this.handleRefreshStatusCheck.bind(this);
    this.timer = null;

    this.state = {
      content: this.props.data.content || '',
      narrativeIsBeingEdited: false,
      wordCount: 0,
      extraNarratives: {},
      openConfirm: false,
      extraNarrativeWords: {},
      isExtraNarrativesWordsLimit: false,
      updatedContent: this.props.data.content || '',
    };
  }

  componentWillUnmount() {
    if (this.timer) clearTimeout(this.timer);
  }

  updateNarrativeContent(newContent) {
    this.setState({
      content: newContent,
    });
    this.handleAutoSave();
  }

  updateWordsCount = newContent => {
    // Count words and then update word count state value with it
    const wordNumber = countWords(newContent);
    this.setState({ wordCount: wordNumber }, () => {});
  };

  onNarrativeChange(val) {
    // Updates wordcount value and triggers autosave if needed
    this.setState({ updatedContent: val }, () => {
      setTimeout(() => this.updateWordsCount(val), 500);
      this.handleAutoSave();
    });
  }

  getRecentContent() {
    // This function is responsible for supplying current narrative content
    // Use it to get narrative content regardless of editor mode
    if (
      !this.props.isFetching &&
      this.props.data.content &&
      this.props.success_actions === 'view'
    ) {
      return this.state.content;
    }
    try {
      return this.state.updatedContent;
    } catch {
      return this.props.data.content || '';
    }
  }

  onCommentAttachedToNarrative = () => {
    const requestKwargs = {
      url_format: {
        _office_: this.props?.data?.report?.office_code,
        _report_: this.props?.data?.report?.id,
        _section_: this.props.activeSectionID,
      },
      storage_key: COMMENTS_LIST,
    };
    this.props.fetchCommentsRelatedToSection(requestKwargs);
    this.props.setSignal(COMMENTS_TARGET_ID);
  };

  unlockAndForcePostNarrative() {
    this.props.dispatchUnlockAndForcePostNarrative(
      { url_format: { _id_: this.props.activeSectionID } },
      this.props.reportPk,
      this.props.activeSectionID,
    );
    this.handleModeChange('view');
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeSectionID !== prevProps.activeSectionID) {
      this.props.dispatchRequestSection(null, {
        url_format: { _id_: this.props.activeSectionID },
      });
      this.props.dispatchContentModeChange(null, null, 'view');
      this.getReportWordcountConfig({});
    } else if (
      !this.props.wordcountConfig &&
      this.props.reportPk &&
      this.props.activeSectionID
    ) {
      this.getReportWordcountConfig({});
    }

    if (this.props.data.content !== prevProps.data.content) {
      this.setState({ content: this.props.data.content });
      this.setState({
        updatedContent: this.props.data.content,
      });
    }

    if (
      !this.props.isFetching &&
      prevProps.isFetching &&
      !!this.props.data.content
    ) {
      this.updateWordsCount(this.getRecentContent());
    }

    if (
      this.props.sectionsContentMode !== 'edit' &&
      !!this.props.data &&
      !!this.props.data.locked &&
      this.props.data.locked.is_you
    ) {
      this.handleModeChange('edit');
    }

    if (
      !this.props.dataSourceRefresh.error &&
      !this.props.dataSourceRefresh.isFetching &&
      prevProps.dataSourceRefresh.isFetching
    ) {
      this.props.dispatchUpdateReportData({
        url_format: { _id_: this.props.reportPk },
      });
    }
  }

  getReportWordcountConfig = params => {
    this.props.dispatchWordcountConfig(this.props.reportPk, {
      url_format: { _id_: this.props.reportPk },
      data: {
        section_id: this.props.activeSectionID,
      },
      ...params,
    });
  };

  isChildOfCrossCuttingResults = (sectionTitle = '') => {
    const crossCuttingResultsTitles = [
      'Progress towards gender equality',
      'Protection and accountability to affected populations',
      'Environment',
    ];

    return crossCuttingResultsTitles.includes(sectionTitle);
  };

  handleRefreshStatusCheck = () => {
    const url = `${getActionAPISource('NOTIFICATIONS_DELAYED_URL')}`;
    if (parseInt(getLocalStorageItem('delayed_notification')) > 0) {
      apiCallAsync(url, {}).then(response => {
        if (response.status === 200) {
          for (const each of response.data) {
            if (!!each.status && each.status === 'success') {
              toast.dismiss(each.section);
              showNotification(
                `Data refresh successfully completed for ${each.section_name}`,
                'success',
                { autoClose: false },
              );
              setLocalStorageItem('delayed_notification', '-', true);

              let sectionID = null;

              // Get new data only if user still on same section or same section group
              if (this.props.activeSectionID === each.section) {
                sectionID = each.section;
              } else if (
                (this.props.data?.title?.startsWith('Strategic outcome') &&
                  each.section_name?.startsWith('Strategic outcome')) ||
                (this.isChildOfCrossCuttingResults(this.props.data.title) &&
                  this.isChildOfCrossCuttingResults(each.section_name))
              ) {
                sectionID = this.props.activeSectionID;
              }

              if (sectionID) {
                this.props.dispatchRequestSection(
                  null,
                  {
                    url_format: { _id_: sectionID },
                  },
                  true,
                );
              }
            } else if (!!each.status && each.status === 'failed') {
              toast.dismiss(each.section);
              showNotification(
                `Failed refresh for ${each.section_name}`,
                'error',
                { autoClose: false },
              );
              setLocalStorageItem('delayed_notification', '-', true);
            }
          }
        }
      });
      setTimeout(() => {
        this.handleRefreshStatusCheck();
      }, 5000);
    }
  };

  handleRefreshDataSources = () => {
    const url = `${getActionAPISource('SECTION_REFRESH_DATA_SOURCE')}`.replace(
      '_id_',
      this.props.activeSectionID,
    );
    apiCallAsync(url, {}, 'post')
      .then(response => {
        if (response.status === 200) {
          showNotification(response.data.result, 'info', {
            autoClose: false,
            toastId: this.props.activeSectionID,
            className: 'data-refresh-notification',
          });
          if (response.data.extra) {
            setLocalStorageItem('delayed_notification', '+', true);
            this.handleRefreshStatusCheck();
          }
        }
      })
      .catch(error => {
        if (error.response) {
          showNotification(
            `${error.message}: ${error.response.data.error}`,
            'error',
            { autoClose: false },
          );
        }
      });
  };

  handleAutoSave = () => {
    if (!this.timer) {
      this.timer = setTimeout(() => {
        if (this.props.sectionsContentMode === 'edit') {
          const data = {
            content: this.getRecentContent(),
            action: 'save',
            extra_narratives: Object.keys(this.state.extraNarratives).map(
              key => {
                return { id: key, content: this.state.extraNarratives[key] };
              },
            ),
          };

          const url = `${API_HOST}/api/acr-sections/${this.props.activeSectionID}/narrative_update/`;
          apiCallAsync(url, data, 'put').then(response => {
            if (response.status === 200) {
              showNotification('Auto save applied', 'success', {
                autoClose: 3500,
              });
            }
          });

          clearTimeout(this.timer);
          this.timer = null;
        }
      }, narrativeAutosaveInterval);
    }
  };

  handleModeChange = mode => {
    const params = {};
    if (mode === 'save' || mode === 'post') {
      const newContent = this.getRecentContent();
      const extraNarratives = Object.keys(this.state.extraNarratives).map(
        key => {
          return { id: key, content: this.state.extraNarratives[key] };
        },
      );

      params.method = 'put';
      params.data = {
        content: newContent,
        words: this.state.wordCount,
        action: mode,
        extra_narratives: extraNarratives,
        extra_narrative_words: this.state.extraNarrativeWords,
      };
      params.url_format = { _id_: this.props.activeSectionID };
      params.success_actions = [
        updateNarrativeValue(newContent),
        requestAPI(REPORT_DETAIL_DATA_KEY, null, {
          url_format: { _id_: this.props.reportPk },
        }),
        requestAPI(SECTION_DETAIL_DATA_KEY, null, {
          url_format: { _id_: this.props.activeSectionID },
        }),
      ];
    } else if (mode === 'cancel') {
      this.props.dispatchCancelNarrativeEditing({
        section: this.props.activeSectionID,
        report: this.props.reportPk,
      });
      this.updateNarrativeContent(this.props.data.content || '');
      this.updateWordsCount(this.props.data.content || '');
    }
    params.url_format = { _id_: this.props.activeSectionID };
    params.report = this.props.reportPk;
    this.props.dispatchContentModeChange(null, params, mode);
    if (mode !== 'edit') {
      this.setState({
        narrativeIsBeingEdited: mode === 'edit',
      });
    }
  };

  getModeChangeCallback = mode => {
    return {
      mode: mode,
      onClick: this.handleModeChange.bind(this, mode),
    };
  };

  getComponentForDataSource = dataSource => {
    const data = {
      dataSource: dataSource,
    };
    let PresentationComponent;

    const dataSourceSlug = DATA_KEY_ON_DATA_SET[dataSource];

    let dataFromBacked;

    if (Array.isArray(dataSourceSlug)) {
      data.lastRefreshInfo = [];
      dataFromBacked = {};

      for (const slug of dataSourceSlug) {
        dataFromBacked[slug] = this.props.data.datasets[slug];
        data.lastRefreshInfo.push(
          this.props.data.refresh_date
            ? this.props.data.refresh_date?.[slug]
            : null,
        );
      }
    } else {
      dataFromBacked = this.props.data.datasets[dataSourceSlug];
      data.lastRefreshInfo = [
        this.props.data.refresh_date
          ? this.props.data.refresh_date[dataSourceSlug]
          : null,
      ];
    }
    data.lastRefreshInfo = data.lastRefreshInfo.filter(x => !!x);

    const retroCompat_2020 = {
      'Progress towards Gender Equality indicators':
        StyledDataArrayTableComponent,
      'Protection indicators': StyledDataArrayTableComponent,
      'Accountability to Affected Population indicators':
        StyledDataArrayTableComponent,
      'Environment indicators': StyledDataArrayTableComponent,
    };

    if (
      dataSource === 'Beneficiaries by Residence Status' &&
      this.props.data.report.project.code === 'DZ02'
    ) {
      data.component = null;
      return data;
    }

    let sdgComponentsData = [];

    let componentsData = [],
      widgetParameters;

    switch (dataSource) {
      case 'Total Beneficiaries':
        PresentationComponent = DATA_SOURCES_WIDGETS_MAP[dataSource];
        data.component = (
          <PresentationComponent {...this.buildPropsForTotalBeneficiaries()} />
        );
        break;
      case 'WFP contribution to SDGs':
        PresentationComponent = DATA_SOURCES_WIDGETS_MAP[dataSource];
        sdgComponentsData = dataFromBacked || [];
        data.noModuleTitle = widgetParameters?.noModuleTitle;
        data.containerClassName = widgetParameters?.containerClassName || '';

        data.component = (
          <div className="sdg-datasource">
            {sdgComponentsData.length === 0 ? (
              <div className="no-data-wrapper">No data</div>
            ) : (
              sdgComponentsData.map((sdgData, index) => {
                return (
                  <PresentationComponent
                    data={sdgData}
                    key={index}
                    {...getDataSourceWidgetsParametersMap[dataSource]}
                  />
                );
              })
            )}
          </div>
        );
        break;
      default:
        PresentationComponent = DATA_SOURCES_WIDGETS_MAP[dataSource];
        if (
          this.props.reportState.state === 'Published' &&
          parseInt(this.props.reportState.period) < 2021
        )
          if (dataSource in retroCompat_2020) {
            PresentationComponent = retroCompat_2020[dataSource];
          }
        componentsData = dataFromBacked || [];
        widgetParameters = getDataSourceWidgetsParametersMap?.[dataSource];
        data.noModuleTitle = widgetParameters?.noModuleTitle;
        data.containerClassName = widgetParameters?.containerClassName || '';
        data.component = (
          <PresentationComponent
            data={componentsData}
            reportState={this.props.reportState.state}
            report={get(this.props, 'data.report')}
            currentPeriod={get(this.props, 'data.report.period')}
            {...getDataSourceWidgetsParametersMap[dataSource]}
          />
        );
    }
    return data;
  };

  buildPropsForTotalBeneficiaries = () => {
    return {
      data:
        this.props.data?.datasets?.total_actual_beneficiaries ||
        this.props.data?.datasets?.total_adjusted_beneficiaries_age_group,
      period: this.props.data?.report?.period,
    };
  };

  onChangeExtraNarratives = data => {
    const words = data['words'];
    if (words) {
      Object.assign(this.state.extraNarrativeWords, words);
      delete data['words'];
    }
    Object.assign(this.state.extraNarratives, data);
  };

  updateExtraNarrativeWordsLimit = isLimited => {
    if (this.state.isExtraNarrativesWordsLimit !== isLimited)
      this.setState({ isExtraNarrativesWordsLimit: isLimited });
  };

  buildPresentationProps = () => {
    const presentationProps = {
      narrativeValue: this.props.data.content,
      activeClickedTab: this.props.activeClickedTab,
      isTitleChanging: this.props.isTitleChanging,
      currentTitle: this.props.currentTitle,
      reportState: this.props.reportData.data.state,
      title: this.props.data.title,
      anchors: this.props.data.anchors,
      infographics: this.props.data.infographics_data,
      infographicsEditorData: this.props.data.infographics_editor,
      freetext_title: this.props.data.freetext_title,
      current_title: this.props.data.current_title,
      canChangeTitle: this.props.data.can_change_title,
      subtitle: this.props.data.subtitle || {},
      content: this.getRecentContent(),
      updateNarrativeContent: this.updateNarrativeContent,
      fetchCommentsRelatedToSection: this.props.fetchCommentsRelatedToSection,
      contentMode: this.props.sectionsContentMode,
      last_modified_date: this.props.data.last_modified_date,
      handleRefreshDataSources: this.handleRefreshDataSources,
      dataSourceRefresh: this.props.dataSourceRefresh,
      has_narrative: this.props.data.narrative,
      is_content_editable: this.props.data.is_content_editable,
      narrative_help_text: this.props.data.narrative_help_text,
      max_narrative_words: this.props.data.max_narrative_words,
      current_narrative_words: this.state.wordCount,
      error: this.props.error,
      narrativeError: this.props.narrative.error,
      wordcountConfig: this.props.wordcountConfig?.data,
      wordcountConfigIsFetching: this.props.wordcountConfig?.isFetching,
      two_letters_project_code: '',
      createNewCommentRelatedToSection:
        this.props.createNewCommentRelatedToSection,
      project_id: '',
      report_id: '',
      activeSectionID: '',
      username: this.props.username,
      refresh_date: this.props.data.refresh_date,
      onCommentAttachedToNarrative: this.onCommentAttachedToNarrative,
      unlockAndForcePostNarrative: this.unlockAndForcePostNarrative,
      locked: this.props.data.locked,
      image_attachment_type: this.props.data.image_attachment_type,
      report: this.props.reportData.data['country']
        ? this.props.reportData.data
        : null,
      commentPermissions: this.props.reportData.data
        ? this.props.reportData.data?.permissions?.['comment_permissions']
        : null,
      infographicsPermissions: this.props.reportData.data
        ? this.props.reportData.data?.permissions?.['infographics_permissions']
        : null,
      extraSectionImagePermissions: this.props.reportData.data
        ? this.props.reportData.data?.permissions?.[
            'has_permission_to_add_image_extra_section'
          ]
        : null,
      cpb_validity: this.props.data.datasets
        ? this.props.data.datasets.cpb_validity
        : null,
      projectPeriod: this.props.projectPeriod,
      gtd_offices: this.props.data.datasets
        ? this.props.data.datasets.gtd_offices
        : null,
      isStrategicOutcome:
        (this.props.data.title || '')
          .toLowerCase()
          .indexOf('strategic outcome') === -1,
      extraNarratives: this.props.data.extra_narratives || [],
      onChangeExtraNarratives: this.onChangeExtraNarratives,
      updateExtraNarrativeWordsLimit: this.updateExtraNarrativeWordsLimit,
      isExtraNarrativesWordsLimit: this.state.isExtraNarrativesWordsLimit,
      dispatchSaveInfographics: this.props.dispatchSaveInfographics,
      dispatchLockInfographics: this.props.dispatchLockInfographics,
      dispatchUnlockInfographics: this.props.dispatchUnlockInfographics,
      infographicsIconsList: this.props.infographicsIconsList,
      tutorialRender: this.props.data.tutorial_render,
      hasInfographics: this.props.data.has_infographics,
      iconsDetails: this.props.iconsDetails,
      dispatchChangeViewStateAction: this.props.dispatchChangeViewStateAction,
      dispatchWordcountConfig: this.getReportWordcountConfig,
      canReadWordcount: this.props.can_read_wordcount,
      canEditWordcount: this.props.can_edit_wordcount,
      canEditInfographics: this.props.canEditInfographics,
      canUnlockInfographics: this.props.canUnlockInfographics,
      handleAutoSave: this.handleAutoSave,
      dispatchSetNarrative: this.props.dispatchSetNarrative,
      dispatchSetExtraNarrative: this.props.dispatchSetExtraNarrative,
    };
    try {
      presentationProps.two_letters_project_code =
        this.props?.data?.report?.project?.code.substring(0, 2);
      presentationProps.project_id = this.props?.data?.report?.project.id;
      presentationProps.report_id = this.props?.data?.report?.id;
      presentationProps.activeSectionID = this.props.activeSectionID;
      presentationProps.office_id = this.props?.data?.report?.office_code;
      // eslint-disable-next-line no-empty
    } catch {}

    if (
      ['view', 'cancel', 'save', 'post'].includes(
        this.props.sectionsContentMode,
      )
    ) {
      presentationProps.contentActions = ['lock'].map(
        this.getModeChangeCallback,
      );
    } else if (this.props.sectionsContentMode === 'lock') {
      presentationProps.contentActions = [];
    } else if (
      this.props.sectionsContentMode === 'edit' &&
      !!presentationProps.has_narrative
    ) {
      if (this.props.isFetching) {
        presentationProps.Editor = <p>loading editor for narrative...</p>;
        presentationProps.contentActions = [];
      } else {
        presentationProps.Editor = (
          <TinyEditorWrapper
            content={presentationProps.content}
            onChange={this.onNarrativeChange}
          />
        );
        presentationProps.narrative_word_number = this.state.wordCount;
        presentationProps.contentActions = ['cancel', 'save', 'post'].map(
          this.getModeChangeCallback,
        );

        if (
          !!this.props.data.max_narrative_words &&
          !!this.state.wordCount &&
          this.state.wordCount > this.props.data.max_narrative_words
        ) {
          presentationProps.current_narrative_is_too_long = true;
        }
      }
    }
    if (this.props.data.data_sources) {
      presentationProps.dataSources = this.props.data.data_sources.map(
        this.getComponentForDataSource,
      );
    } else {
      presentationProps.dataSources = [];
    }
    return presentationProps;
  };

  componentDidMount() {
    this.handleRefreshStatusCheck();
    this.props.dispatchGetIconsDetails();
  }

  render() {
    const payload = this.buildPresentationProps();
    return <WorkspacePresentation {...payload} />;
  }
}

class WorkspacePresentation extends Component {
  constructor(props) {
    super(props);
    this.forcePostOnClick = this.forcePostOnClick.bind(this);

    this.state = {
      anchorName: '',
      narrativeRaw: '',
      isEditInfographics: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.content !== prevProps.content) {
      this.setState({
        narrativeRaw: this.props.content,
      });
    }

    if (this.props.activeSectionID !== prevProps.activeSectionID) {
      this.props.dispatchChangeViewStateAction({
        activeClickedTab: NARRATIVE,
        isTitleChanging: false,
        currentTitle: '',
      });
    }

    if (
      prevProps.username !== this.props.username ||
      prevProps.infographicsEditorData?.infographics_editor !==
        this.props.infographicsEditorData?.infographics_editor
    )
      if (
        this.props.username ===
        this.props.infographicsEditorData?.infographics_editor
      ) {
        this.setState({ isEditInfographics: true });
      }
  }

  forcePostOnClick() {
    this.props.unlockAndForcePostNarrative();
  }

  handleAnchorAdding = anchorName => {
    this.setState(() => {
      return { anchorName: anchorName };
    });
  };

  handleContentUpdate = content => {
    this.setState(() => {
      return { narrativeRaw: content };
    });
  };

  createComment = (anchorName, anchorID, anchorContent) => {
    const requestData = {
      content: anchorContent,
      title: anchorName,
      anchor: anchorName,
      anchor_id: anchorID,
    };
    this.props.createNewCommentRelatedToSection({
      url_format: {
        _office_: this.props.office_id,
        _report_: this.props.report_id,
        _section_: this.props.activeSectionID,
      },
      method: 'post',
      data: requestData,
      storage_key: `${COMMENTS_LIST}_created`,
      success_actions: [
        sendSignalsAction({
          [this.props.commentsContainerID]: {
            [UPDATE]: true,
            [SUCCESS_REQUEST]: true,
          },
        }),
      ],
    });
  };

  performAnchorAddingRequest = (
    value,
    anchorName,
    anchorID,
    anchorComment,
    isExtra = false,
  ) => {
    const { activeSectionID } = this.props;
    const url = `${API_HOST}/api/acr-sections/${activeSectionID}/add_anchors/`;
    const request_body = { content: value };
    if (isExtra) {
      request_body.isExtra = true;
    }
    apiCallAsync(url, request_body, 'post').then(result => {
      this.setState({ value: result.data.content }, () => {
        if (!isExtra) {
          this.props.updateNarrativeContent(value);
        }
        this.createComment(anchorName, anchorID, anchorComment);
        const requestKwargs = {
          url_format: {
            _office_: this.props.office_id,
            _report_: this.props.report_id,
            _section_: this.props.activeSectionID,
          },
          storage_key: COMMENTS_LIST,
        };

        this.props.fetchCommentsRelatedToSection(requestKwargs);
        this.props.onCommentAttachedToNarrative();

        // Remove draftComment from view state, if present
        this.props.dispatchChangeViewStateAction({ draftComment: undefined });

        if (!isExtra) {
          this.props.dispatchSetNarrative(result.data.content);
        } else {
          this.props.dispatchSetExtraNarrative(
            result.data.content,
            result.data.narrative_id,
          );
        }
      });
    });
  };

  render() {
    const anchorID = `${this.props.activeSectionID}-highlight-${uuidv4()}`;
    let content = this.props.content
      ? this.props.content
      : this.state.narrativeRaw;
    let show_wordcount = true;
    if (this.props.contentMode === 'edit') {
      content = this.props.Editor;
    } else {
      if (this.props.contentMode === 'lock') {
        content = (
          <>
            <span>Locking the narrative...</span>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </>
        );
      } else {
        show_wordcount = false;
        if (
          (this.props.locked &&
            this.props.locked.user &&
            !this.props.locked.is_you) ||
          !this.props.commentPermissions?.add_comment ||
          this.props.reportState === 'Published'
        ) {
          content = <div dangerouslySetInnerHTML={{ __html: content }} />;
        } else {
          content = (
            <AnchorModal
              sectionId={this.props.activeSectionID}
              text={this.props.narrativeValue}
              performAnchorAddingRequest={this.performAnchorAddingRequest}
              isExtra={false}
            />
          );
        }
      }
    }

    const wordCountAllowedSections = [
      'Programme performance',
      'Cross-cutting Results',
    ];
    const wordcount_render =
      wordCountAllowedSections.includes(this.props.current_title) &&
      this.props.canReadWordcount;

    const headingWidgets = [],
      remainingWidgets = [];

    const headingWidgetNames = SPECIAL_LOCATION_OF_WIDGETS.header || [];
    for (let i = 0, len = this.props.dataSources.length; i < len; i++) {
      const dataSourceObject = this.props.dataSources[i];
      if (headingWidgetNames.indexOf(dataSourceObject.dataSource) !== -1) {
        headingWidgets.push(dataSourceObject);
      } else {
        remainingWidgets.push(dataSourceObject);
      }
    }

    const narrativeErrorComponents = buildErrorComponents(
      this.props.narrativeError,
    );

    const { report = {} } = this.props;
    const period = get(report, 'period', '');

    const isVersionsTab = this.props.activeClickedTab === VERSIONS;

    return (
      <>
        <Helmet>
          <title>{getReportPageTitle(report?.country, period)}</title>
        </Helmet>
        <main
          className={isVersionsTab ? 'section-versions' : 'section-detail'}
          id="section-wrapper-main"
        >
          <SectionControls
            error={this.props.error}
            dataSourceRefresh={this.props.dataSourceRefresh}
            handleRefreshDataSources={this.props.handleRefreshDataSources}
          />
          {isVersionsTab ? (
            <VersionsTab headingWidgets={headingWidgets} />
          ) : (
            <>
              {this.props.hasInfographics && (
                <Infographics
                  isEdit={this.state.isEditInfographics}
                  setEdit={val => this.setState({ isEditInfographics: val })}
                  data={this.props.infographics?.widget_items}
                  editorData={this.props.infographicsEditorData}
                  currentUserUsername={this.props.username}
                  canEditInfographics={this.props.canEditInfographics}
                  canUnlockInfographics={this.props.canUnlockInfographics}
                  alignment={this.props.infographics?.alignment}
                  renderTutorial={this.props.tutorialRender}
                  saveAction={objToSave =>
                    this.props.dispatchSaveInfographics({
                      ...objToSave,
                      section_id: this.props.activeSectionID,
                      widget_pk: this.props.infographics?.widget_pk,
                    })
                  }
                  lockAction={() =>
                    this.props.dispatchLockInfographics({
                      section_id: this.props.activeSectionID,
                    })
                  }
                  unlockAction={() =>
                    this.props.dispatchUnlockInfographics({
                      section_id: this.props.activeSectionID,
                    })
                  }
                  iconsList={this.props.iconsDetails}
                  reportState={
                    this.props.report ? this.props.report.state : null
                  }
                  domainLink={process.env.REACT_APP_DOMAIN}
                  sendGAEvent={sendGAEvent}
                />
              )}

              {!!this.props.report &&
                !!this.props.image_attachment_type &&
                !this.props.current_title.includes('trategic outcom') && (
                  <ImageUploader />
                )}

              {!!this.props.report &&
                (this.props.current_title === 'Summary' ||
                  this.props.current_title === '2020 Overview' ||
                  this.props.current_title === 'Overview') && (
                  <ContactInfo
                    reportPk={this.props.report.pk}
                    reportIsPublished={this.props.report.state === 'Published'}
                  />
                )}

              {!!this.props.report && this.props.report_id && (
                <Title
                  initialContent={this.props.current_title}
                  isEditable={
                    this.props.canChangeTitle &&
                    this.props.report.state !== 'Published'
                  }
                  sectionId={this.props.activeSectionID}
                  headingWidgets={headingWidgets}
                  subtitle={this.props.subtitle}
                />
              )}

              {this.props.isStrategicOutcome &&
                !!this.props.wordcountConfig &&
                wordcount_render &&
                !this.props.wordcountConfigIsFetching && (
                  <Module className="section-heading">
                    <ModuleBody>
                      <WordLimitPanel
                        data={this.props.wordcountConfig}
                        dispatchWordcountConfig={
                          this.props.dispatchWordcountConfig
                        }
                        sectionID={this.props.activeSectionID}
                        sectionTitle={this.props.current_title}
                        isEditable={
                          this.props.report.state !== 'Published' &&
                          this.props.canEditWordcount
                        }
                      />
                    </ModuleBody>
                  </Module>
                )}
              {!this.props.has_narrative ? null : (
                <Module
                  className="narrative-editor"
                  data-test-id="main-narrative"
                >
                  <ModuleHeader>
                    <span
                      data-test-id="narrative-title"
                      className="narrative-title"
                    >
                      {this.props.has_narrative}
                      Narrative{' '}
                      {this.props.max_narrative_words
                        ? `(Up to ${this.props.max_narrative_words} Words)`
                        : null}
                    </span>
                    <hr className="line-break" />
                    <p className="actions">
                      {this.props.locked &&
                      this.props.locked.is_force_post &&
                      !this.props.locked.is_you &&
                      this.props.report &&
                      this.props.report.state !== 'Published' ? (
                        <Button
                          onClick={() => {
                            sendGAEvent('force_post_narrative_btn_clicked');
                            this.forcePostOnClick();
                          }}
                          data-test-id="force-unlock-button"
                        >
                          Unlock and force post
                        </Button>
                      ) : (
                        this.props.contentActions.map((item, index) =>
                          this.props.is_content_editable &&
                          this.props.report &&
                          this.props.report.state !== 'Published' ? (
                            <Button
                              key={index}
                              onClick={() => {
                                if (item.mode === 'lock') {
                                  sendGAEvent('edit_narrative_btn_clicked');
                                }

                                item.onClick();
                              }}
                              data-test-id={`${
                                MODE_TITLE_MAP[
                                  item.mode === 'lock' ? 'edit' : item.mode
                                ] || item.mode
                              }-button`}
                              disabled={
                                ['save', 'post'].includes(item.mode) &&
                                (this.props.current_narrative_is_too_long ||
                                  this.props.isExtraNarrativesWordsLimit)
                              }
                              className={
                                item.mode === 'cancel'
                                  ? 'wfp--btn--secondary'
                                  : ''
                              }
                            >
                              {MODE_TITLE_MAP[
                                item.mode === 'lock' ? 'edit' : item.mode
                              ] || item.mode}
                            </Button>
                          ) : (
                            <span key={index} />
                          ),
                        )
                      )}
                    </p>
                  </ModuleHeader>
                  <ModuleBody className="narrative">
                    {this.props.narrative_help_text ? (
                      <p className="narrative-help-text">
                        <span>
                          <i>
                            <strong>Narrative content notes: </strong>
                          </i>
                        </span>
                        {this.props.narrative_help_text}
                      </p>
                    ) : null}
                    <h1>{this.props.is_content_editable}</h1>

                    {!!this.props.current_narrative_is_too_long && (
                      <div
                        className="narrative-errors"
                        data-test-id="narrative-error-message"
                      >
                        Narrative is exceeding the word limit (
                        {this.props.current_narrative_words}/
                        {this.props.max_narrative_words})
                      </div>
                    )}
                    <article className="narrative-content">
                      {content}
                      {show_wordcount ? (
                        <div
                          className="custom-wordcount"
                          data-test-id="wordcount-label"
                        >
                          Wordcount: {this.props.narrative_word_number} out of{' '}
                          {this.props.max_narrative_words}
                        </div>
                      ) : (
                        ''
                      )}
                    </article>

                    {narrativeErrorComponents.length > 0 && (
                      <ul className="narrative-errors">
                        {narrativeErrorComponents}
                      </ul>
                    )}
                  </ModuleBody>
                  <ModuleFooter>
                    {this.props.locked && this.props.locked.user ? (
                      <aside className="float-left">
                        <strong>{this.props.locked.user}</strong>
                        <br />
                        <span className="float-left">
                          At: <strong>{this.props.last_modified_date}</strong>
                        </span>
                      </aside>
                    ) : null}
                  </ModuleFooter>
                </Module>
              )}

              <ExtraNarratives
                data={this.props.extraNarratives}
                locked={this.props.locked}
                report={this.props.report}
                is_content_editable={this.props.is_content_editable}
                titleMap={MODE_TITLE_MAP}
                contentMode={this.props.contentMode}
                last_modified_date={this.props.last_modified_date}
                onChange={this.props.onChangeExtraNarratives}
                isWordLimit={this.props.updateExtraNarrativeWordsLimit}
                handleAnchorAdding={this.handleAnchorAdding}
                handleContentChange={this.handleContentUpdate}
                handleAutoSave={this.props.handleAutoSave}
                sectionID={this.props.activeSectionID}
                anchorID={anchorID}
                isReportPublished={this.props.reportState === 'Published'}
                performAnchorAddingRequest={(
                  value,
                  anchorName,
                  anchor_id,
                  anchorComment,
                ) => {
                  this.performAnchorAddingRequest(
                    value,
                    anchorName,
                    anchor_id,
                    anchorComment,
                  );
                }}
                canCreateNarrativeComments={
                  this.props.commentPermissions?.add_comment
                }
              />

              {remainingWidgets.map((item, index) => {
                const finacialOverviewTitle =
                  'Annual CSP Financial Overview by Strategic Outcome and Activity';
                const title =
                  item.dataSource === finacialOverviewTitle
                    ? `${finacialOverviewTitle} (Amount in USD)`
                    : item.dataSource;

                return (
                  <Module
                    key={index}
                    data-test-id={item.dataSource}
                    className={`data-source restricted-chart-width ${
                      item.noModuleTitle ? 'special-module-title' : ''
                    } ${item.containerClassName}`}
                  >
                    {!item.noModuleTitle ? (
                      <ModuleHeader>{title}</ModuleHeader>
                    ) : null}
                    <ModuleBody>{item.component}</ModuleBody>
                    {item.lastRefreshInfo && (
                      <LatestRefreshInfo refreshInfo={item.lastRefreshInfo} />
                    )}
                  </Module>
                );
              })}
              <DataNotes />
              <BottomLinks
                report={this.props.report}
                currentTitle={this.props.current_title}
              />
              <hr className="line-break" />
            </>
          )}
        </main>
      </>
    );
  }
}

const mapStateToProps = state => {
  let narrativeContent = null,
    narrativeEditor = '',
    username = '',
    infographics = [],
    infographicsEditorData = {},
    reportPk = '',
    reportState = '',
    anchors = [],
    projectPeriod = '',
    infographicsIconsList = [],
    iconsDetails = [];

  try {
    narrativeContent =
      state.viewData[SECTION_DETAIL_DATA_KEY].data.content || null;
    anchors = state.viewData[SECTION_DETAIL_DATA_KEY].data.anchors;
    // eslint-disable-next-line no-empty
  } catch {}
  try {
    infographics =
      state.viewData[SECTION_DETAIL_DATA_KEY].data.infographics_data || null;
    // eslint-disable-next-line no-empty
  } catch {}

  try {
    infographicsEditorData =
      state.viewData[SECTION_DETAIL_DATA_KEY]?.data.infographics_editor || null;
    // eslint-disable-next-line no-empty
  } catch {}

  try {
    infographicsIconsList =
      state.viewData[SECTION_DETAIL_DATA_KEY]?.data?.infographics_icon_set
        ?.icon_set || null;
    // eslint-disable-next-line no-empty
  } catch {}

  try {
    narrativeEditor = state.viewData[SECTION_DETAIL_DATA_KEY].data.editor || '';
    projectPeriod =
      state.viewData[SECTION_DETAIL_DATA_KEY].data.report.project.period || '';
    // eslint-disable-next-line no-empty
  } catch {}

  try {
    username = state.viewData[REPORT_DETAIL_DATA_KEY].data.username || '';
    reportPk = state.viewData[REPORT_DETAIL_DATA_KEY].data.pk;
    reportState = state.viewData[REPORT_DETAIL_DATA_KEY].data;
    // eslint-disable-next-line no-empty
  } catch {}

  try {
    iconsDetails = state.viewData?.[ICONS_DETAILS_KEY]?.data;
    // eslint-disable-next-line no-empty
  } catch {}

  return {
    data: {},
    isFetching: false,
    error: null,
    anchors,
    infographics,
    infographicsEditorData,
    iconsDetails,
    infographicsIconsList,
    activeSectionID: 0,
    sectionsContentMode: 'view',
    username,
    reportPk,
    reportState,
    projectPeriod,
    dataSourceRefresh: {
      isFetching: false,
      ...state.viewData[SECTION_REFRESH_DATA_SOURCE_KEY],
    },
    narrative: {
      isFetching: false,
      error: null,
      data: {
        content: narrativeContent,
        editor: narrativeEditor,
      },
      ...state.viewData[NARRATIVE_UPDATE_DATA_KEY],
    },
    wordcountConfig: state.viewData?.[WORDCOUNT_CONFIG],
    can_read_wordcount:
      state.viewData?.[REPORT_DETAIL_DATA_KEY]?.data?.permissions
        ?.has_permission_to_read_word_count,
    can_edit_wordcount:
      state.viewData?.[REPORT_DETAIL_DATA_KEY]?.data?.permissions
        ?.has_permission_to_edit_word_count,
    canEditInfographics:
      state.viewData?.[REPORT_DETAIL_DATA_KEY]?.data?.permissions
        ?.infographics_permissions?.edit_infographics,
    canUnlockInfographics:
      state.viewData?.[REPORT_DETAIL_DATA_KEY]?.data?.permissions
        ?.infographics_permissions?.unlock_infographics,
    reportData: {
      isFetching: false,
      error: null,
      reportState: reportState,
      data: {},
      ...state.viewData[REPORT_DETAIL_DATA_KEY],
    },
    ...state.viewData[SECTION_DETAIL_DATA_KEY],
    ...state.viewState,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchChangeViewStateAction: params => {
      dispatch(changeViewStateAction(params));
    },
    dispatchUpdateReportData: (params = {}) => {
      dispatch(requestAPI(REPORT_DETAIL_DATA_KEY, null, params));
    },
    createNewCommentRelatedToSection: (params = {}) => {
      dispatch(requestAPI(COMMENTS_LIST, null, params));
    },
    dispatchRequestSection: (id, params = {}, holdData = false) => {
      if (holdData) {
        dispatch(requestAPIHoldingData(SECTION_DETAIL_DATA_KEY, id, params));
      } else {
        dispatch(requestAPILatest(SECTION_DETAIL_DATA_KEY, id, params));
      }
      dispatch(clearApiKeys([SECTION_REFRESH_DATA_SOURCE_KEY]));
    },
    dispatchRefreshDataSource: sectionId => {
      dispatch(selectSectionsDataSource(sectionId));
    },
    dispatchContentModeChange: (id, params, mode) => {
      dispatch(changeContentMode(mode));
      if (mode === 'save' || mode === 'post') {
        if (mode === 'save') {
          params.success_actions.push(changeContentMode('edit'));
        } else {
          params.success_actions.push(changeContentMode('view'));
        }
        dispatch(requestAPI(NARRATIVE_UPDATE_DATA_KEY, id, params));
      }
      if (mode === 'edit') {
        dispatch(
          requestAPI(REPORT_DETAIL_DATA_KEY, null, {
            url_format: { _id_: params['report'] },
          }),
        );
      }
      if (mode === 'lock') {
        const action = lockNarrative({
          section: params['url_format']['_id_'],
          report: params['report'],
          success_actions: [
            requestAPI(REPORT_DETAIL_DATA_KEY, null, {
              url_format: { _id_: params['report'] },
            }),
            requestAPI(SECTION_DETAIL_DATA_KEY, null, {
              url_format: params.url_format,
            }),
            changeContentMode('edit'),
          ],
          failure_actions: [
            requestAPI(REPORT_DETAIL_DATA_KEY, null, {
              url_format: { _id_: params['report'] },
            }),
            requestAPI(SECTION_DETAIL_DATA_KEY, null, {
              url_format: params.url_format,
            }),
            changeContentMode('view'),
          ],
        });
        dispatch(action);
      }
    },
    setSignalToToggle: id => {
      dispatch(toggleSignalsAction({ [id]: [SHOW] }));
    },
    setSignal: id => {
      dispatch(
        sendSignalsAction({
          [id]: { [UPDATE]: true },
        }),
      );
    },
    dispatchSaveInfographics: params => {
      const payload = {
        method: 'post',
        data: params,
      };
      dispatch(UpdateInfographicsAction(payload));
    },
    dispatchLockInfographics: params => {
      const payload = {
        method: 'post',
        data: params,
      };
      dispatch(LockInfographicsAction(payload));
    },
    dispatchUnlockInfographics: params => {
      const payload = {
        method: 'post',
        data: params,
      };
      dispatch(UnlockInfographicsAction(payload));
    },
    dispatchCancelNarrativeEditing: params => {
      params.success_actions = [
        requestAPI(REPORT_DETAIL_DATA_KEY, null, {
          url_format: { _id_: params['report'] },
        }),
        requestAPI(SECTION_DETAIL_DATA_KEY, null, {
          url_format: { _id_: params['section'] },
        }),
        changeContentMode('view'),
      ];
      dispatch(cancelNarrativeEditing(params));
    },
    dispatchUnlockAndForcePostNarrative: (params, reportPk, sectionPk) => {
      const subsequent_actions = [
        requestAPI(REPORT_DETAIL_DATA_KEY, null, {
          url_format: { _id_: reportPk },
        }),
        requestAPI(SECTION_DETAIL_DATA_KEY, null, {
          url_format: { _id_: sectionPk },
        }),
      ];
      params.success_actions = subsequent_actions;
      params.failure_actions = subsequent_actions;
      params.method = 'post';
      dispatch(
        requestAPI(NARRATIVE_UNLOCK_AND_FORCE_POST_DATA_KEY, null, params),
      );
    },
    fetchCommentsRelatedToSection: (params = {}) => {
      dispatch(requestAPI(COMMENTS_LIST, null, params));
    },
    dispatchGetIconsDetails: () => {
      dispatch(getIconsDetails());
    },
    dispatchWordcountConfig: (reportID, params) => {
      dispatch(requestAPIHoldingData(WORDCOUNT_CONFIG, reportID, params));
    },
    dispatchSetNarrative: newNarrativeVal => {
      dispatch(
        setNarrativeVal({
          val: newNarrativeVal,
        }),
      );
    },
    dispatchSetExtraNarrative: (newNarrativeVal, id) => {
      dispatch(
        setExtraNarrativeVal({
          val: newNarrativeVal,
          id,
        }),
      );
    },
  };
};

const Workspace = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkspaceContainer);

export default Workspace;
