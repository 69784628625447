import { Button, Loading } from '@wfp/ui';
import React, { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

import {
  getActionAPISource,
  receiveAPIResponse,
  requestAPI,
} from 'src/redux/actions';
import { pullPdf, stopPullingPdf } from 'src/redux/report/actions';
import {
  ACR_PDF_REPORT_FILE_PROXY_URL,
  GET_LIST_ACR_PDF_REPORTS_KEY,
  POST_REQUEST_ACR_PDF_REPORT_KEY,
  PULL_REQUESTED_PDF,
  REPORT_DETAIL_DATA_KEY,
} from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { sendGAEvent, showNotification } from 'src/utils';

const PDFGeneratorButton = () => {
  const dispatch = useDispatch();

  const viewState = useSelector((state: RootStateOrAny) => state.viewState);
  const reportDetailData = useSelector(
    (state: RootStateOrAny) => state.viewData[REPORT_DETAIL_DATA_KEY]?.data,
  );
  const reportVersions = useSelector(
    (state: RootStateOrAny) =>
      state.viewData[GET_LIST_ACR_PDF_REPORTS_KEY]?.data,
  );
  const isPullingPdf = viewState?.[PULL_REQUESTED_PDF];

  // Stop pulling PDF when component will unmounted
  useEffect(() => {
    return () => {
      stopPullingPDF();
      resetPDFReportsList();
    };
  }, []);

  useEffect(() => {
    if (!isPullingPdf && reportVersions?.report?.uuid) {
      getPDF(reportVersions.report.uuid);
    }
  }, [isPullingPdf, reportVersions]);

  function resetPDFReportsList() {
    dispatch(
      receiveAPIResponse(
        GET_LIST_ACR_PDF_REPORTS_KEY,
        {},
        {
          url_format: {
            _id_: reportDetailData.pk,
          },
        },
      ),
    );
  }

  function stopPullingPDF() {
    if (isPullingPdf) {
      dispatch(stopPullingPdf());
    }
  }

  function startPullingPDF() {
    if (!isPullingPdf && reportDetailData) {
      dispatch(
        pullPdf(
          requestAPI(GET_LIST_ACR_PDF_REPORTS_KEY, null, {
            url_format: { _id_: reportDetailData.pk },
          }),
        ),
      );
    }
  }

  function requestPDF() {
    if (!reportDetailData) return;

    dispatch(
      requestAPI(POST_REQUEST_ACR_PDF_REPORT_KEY, null, {
        data: {},
        url_format: { _id_: reportDetailData.pk },
        method: 'post',
      }),
    );

    startPullingPDF();
  }

  async function getPDF(uuid: string) {
    const requestUrl = getActionAPISource(ACR_PDF_REPORT_FILE_PROXY_URL);
    const url = requestUrl.replace(new RegExp('_uuid_', 'g'), uuid);

    apiCallAsync(url)
      .then(result => {
        showNotification(
          <>
            PDF preview is ready.
            <br />
            <a
              className="open-pdf-link"
              href={result.data.s3_link}
              target="_blank"
              rel="noreferrer"
            >
              Open PDF
            </a>
          </>,
          'success',
          {
            autoClose: false,
            closeOnClick: false,
          },
        );
        resetPDFReportsList();
      })
      .catch((err: any) => {
        console.log(err);
        resetPDFReportsList();
      });
  }

  return (
    <Button
      className="wfp--btn--secondary"
      data-test-id="PDF-preview-button"
      disabled={isPullingPdf}
      onClick={() => {
        sendGAEvent('pdf_preview_btn_clicked');
        requestPDF();
      }}
    >
      {isPullingPdf ? (
        <>
          <Loading className="wfp--loading--small" withOverlay={false} active />
          Preparing PDF ...
        </>
      ) : (
        <>PDF Preview</>
      )}
    </Button>
  );
};

export default PDFGeneratorButton;
