import { Modal } from '@wfp/ui';
import type { FC } from 'react';
import React from 'react';

interface Props {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  heading: string;
  content?: JSX.Element;
  primaryButtonDisabled?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  customClassName?: string;
  wide?: boolean;
}

const ConfirmationModal: FC<Props> = props => {
  const {
    open,
    onSubmit,
    onClose,
    heading,
    content = '',
    primaryButtonDisabled = false,
    primaryButtonText = 'OK',
    secondaryButtonText = 'Cancel',
    customClassName = '',
    wide = false,
  } = props;
  return (
    <Modal
      open={open}
      onRequestSubmit={onSubmit}
      onSecondarySubmit={onClose as any}
      onRequestClose={onClose}
      primaryButtonDisabled={primaryButtonDisabled}
      modalHeading={heading}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      iconDescription="Close the modal"
      data-test-id="confirmation-popup"
      className={customClassName}
      wide={wide}
    >
      {content}
    </Modal>
  );
};

export default ConfirmationModal;
