import React, { FC } from 'react';

import { IIssuesOverviewActiveFilters, INarrative } from 'src/types';
import IssueItem from '../IssueItem';

interface Props {
  narratives: Partial<INarrative>[];
  activeFilters: IIssuesOverviewActiveFilters;
  untaggedComments: boolean;
}

const IssuesOverviewContentList: FC<Props> = ({
  narratives,
  activeFilters,
  untaggedComments,
}) => (
  <div>
    {narratives?.map((narrative, index) => (
      <div key={index}>
        <div className="io-content-narrative-wrapper">
          {untaggedComments && narrative?.issues?.length > 0 && (
            <h4 className="io-content-narrative-untagged">Untagged comments</h4>
          )}
          {!untaggedComments && <h4>{narrative.title}</h4>}
          {!untaggedComments &&
            narrative.content &&
            activeFilters.showNarratives && (
              <div
                className="io-content-narrative-wrapper-content"
                dangerouslySetInnerHTML={{
                  __html: narrative.content,
                }}
              />
            )}
          {narrative?.issues?.length > 0 && (
            <>
              <div className="io-content-narrative-wrapper-subtitle">
                {untaggedComments
                  ? `Comments not linked to a specific narrative`
                  : `Comments on "${narrative.title}"`}
              </div>
              {narrative.issues?.map(issue => (
                <IssueItem issue={issue} key={issue.pk} />
              ))}
            </>
          )}
        </div>
        {index < narratives.length - 1 && (
          <div className="io-content-narratives-separator"></div>
        )}
      </div>
    ))}
  </div>
);

export default IssuesOverviewContentList;
