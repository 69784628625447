import { Module, ModuleBody, ModuleFooter, ModuleHeader } from '@wfp/ui';
import React, { useState } from 'react';

import AnchorModal from 'src/components/ReportTemplate/anchorModal';
import TinyEditorWrapper from 'src/components/ReportTemplate/WorkspaceACR2023/TinyEditorWrapper';
import { replaceNbsps } from 'src/utils';

function ExtraNarratives(props) {
  const { data, ...restProps } = props;

  function updateNarratives(id, content, words) {
    if (words) {
      props.onChange({ [id]: content, ['words']: { [id]: words } });
    } else {
      props.onChange({ [id]: content });
    }
  }

  if (data && data.length)
    return (
      <React.Fragment>
        {data.map(item => (
          <ExtraNarrative
            key={item.id}
            data={item}
            setExtraNarratives={updateNarratives}
            {...restProps}
          />
        ))}
      </React.Fragment>
    );
  return null;
}

export function ExtraNarrative(props) {
  const narrative = props.data;
  const [narrativeContent, setNarrativeContent] = useState(
    narrative.content ? narrative.content : '',
  );

  function onChange(val) {
    props.setExtraNarratives(narrative.id, val, getWordCount(val));
    setNarrativeContent(val);
    props.isWordLimit(getWordCount(val) > narrative.limit);
    props.handleAutoSave();
  }

  let content = narrativeContent;
  if (props.contentMode === 'edit') {
    content = (
      <TinyEditorWrapper content={narrativeContent} onChange={onChange} />
    );
  } else {
    if (props.contentMode === 'lock') {
      content = (
        <>
          <span>Locking the narrative...</span>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </>
      );
    } else if (!props.canCreateNarrativeComments || props.isReportPublished) {
      content = <div dangerouslySetInnerHTML={{ __html: content }} />;
    } else {
      content = (
        <AnchorModal
          sectionId={props.sectionID}
          text={narrative.content}
          performAnchorAddingRequest={props.performAnchorAddingRequest}
          isExtra={true}
        />
      );
    }
  }

  function getWordCount(content) {
    let cont = content || narrativeContent;
    if (cont) {
      cont.trim();
      cont = replaceNbsps(cont)
        .replaceAll(/<[^>]+>/g, '')
        .replaceAll('&nbsp;', ' ')
        .replaceAll(/\n/g, ' ');
      cont = cont
        .replace(/<[^>]+>/g, '')
        .replace('&nbsp;', ' ')
        .replace(/\n/g, ' ');
      cont = cont.replace(/&ndash;/g, ' &ndash;');
      //exclude  start and end white-space
      cont = cont.replace(/(^\s*)|(\s*$)/gi, '');
      //2 or more space to 1
      cont = cont.replace(/[ ]{2,}/gi, ' ');
      // exclude newline with a start spacing
      cont = cont.replace(/\n /, '\n');
      return cont.split(' ').filter(function (str) {
        return str !== '';
      }).length;
    }
    return 0;
  }

  const narrativeWords = getWordCount();

  return (
    <React.Fragment>
      <Module className="section-heading">
        <ModuleHeader data-test-id="risk-management-title">
          {narrative.title}
        </ModuleHeader>
      </Module>

      <Module
        className="narrative-editor"
        data-test-id="risk-management-narrative"
      >
        <ModuleHeader>
          <span>
            Narrative {narrative ? `(Up to ${narrative.limit} Words)` : null}
          </span>
        </ModuleHeader>
        <ModuleBody className="narrative">
          {narrativeWords > narrative.limit && (
            <div className="narrative-errors">
              Narrative is exceeding the word limit ({narrativeWords}/
              {narrative.limit})
            </div>
          )}
          <article className="narrative-content">
            {content}
            {props.contentMode === 'edit' ? (
              <div className="custom-wordcount">
                Wordcount: {narrativeWords} out of {narrative.limit}
              </div>
            ) : null}
          </article>
        </ModuleBody>

        <ModuleFooter>
          {props.locked && props.locked.user ? (
            <aside className="float-left">
              <strong>{props.locked.user}</strong>
              <br />
              <span className="float-left">
                At: <strong>{props.last_modified_date}</strong>
              </span>
            </aside>
          ) : null}
        </ModuleFooter>
      </Module>
    </React.Fragment>
  );
}

export default ExtraNarratives;
