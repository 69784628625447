import { Button } from '@wfp/ui';
import React, { type FC } from 'react';

import { getActionAPISource } from 'src/redux/actions';
import { apiCallAsync } from 'src/redux/sagas';

interface Props {
  reportId: number;
}

const DownloadCoverPreviewButton: FC<Props> = ({ reportId }) => {
  async function handleCoverPreview() {
    const url = getActionAPISource('PDF_COVER_PREVIEW').replace(
      '_id_',
      reportId,
    );

    await apiCallAsync(url, {}, 'get').then(result => {
      if (result.status === 200) {
        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];

          for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
          ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }
          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        };
        const blob = b64toBlob(
          result.data.encoded_cover,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        );
        const filename = 'cover_preview.pdf';

        const downloadLink = window.document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob([blob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
        );
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    });
  }

  return (
    <Button
      kind="secondary"
      id="image-preview"
      onClick={handleCoverPreview}
      data-test-id="cover-preview-button"
    >
      PDF Cover Preview
    </Button>
  );
};

export default DownloadCoverPreviewButton;
