// @ts-expect-error: Wrong export types declaration in WFP Ui Kit
import { FileUploaderButton } from '@wfp/ui';
import React, { FC, useEffect, useState } from 'react';

import Upload from 'src/assets/images/upload-icon02.svg';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { API_HOST } from 'src/constants';
import { apiCallAsync } from 'src/redux/sagas';
import { showNotification } from 'src/utils';

interface Props {
  closeMenu: () => void;
  fetchData: () => void;
}

const pollingSecondsInterval = 25;

const ImportUserBtn: FC<Props> = ({ closeMenu, fetchData }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [file, setFile] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [taskId, setTaskId] = useState(null);

  useEffect(() => {
    if (!file) return;

    setShowConfirmationModal(true);
  }, [file]);

  useEffect(() => {
    if (taskId !== null) {
      checkImportStatus();
    }

    const intId =
      taskId !== null
        ? setInterval(() => {
            checkImportStatus();
          }, pollingSecondsInterval * 1000)
        : null;

    if (taskId === null && intId) {
      clearInterval(intId);
    }

    return () => {
      if (intId) clearInterval(intId);
    };
  }, [taskId]);

  async function checkImportStatus() {
    const url = `${API_HOST}/api/load-users/?task_id=${taskId}`;

    try {
      const res = await apiCallAsync(url);
      if (res.data?.message === 'Task finished successfully') {
        setShowLoader(false);
        setTaskId(null);
        showNotification('Users imported successfully', 'success', {
          autoClose: false,
        });
        fetchData();
      }
    } catch {
      console.log('Error while checking users import status');
      setShowLoader(false);
      setTaskId(null);
    }
  }

  async function fileUpload() {
    setShowLoader(true);
    const url = `${API_HOST}/api/load-users/`;
    const formData = new FormData();
    formData.append('file', file);

    setShowConfirmationModal(false);

    showNotification('Importing users', 'info', {
      autoClose: false,
    });

    try {
      const response = await apiCallAsync(url, formData, 'post', null, {
        'content-type': 'multipart/form-data',
      });

      if (!response.data?.task_id) {
        throw Error;
      }

      setTaskId(response.data.task_id);
      setShowConfirmationModal(false);
      setFile(null);
    } catch (e) {
      console.log('Error: ', e);

      showNotification(
        'Error while importing users. Please retry later',
        'error',
        { autoClose: false },
      );

      setShowLoader(false);
      setShowConfirmationModal(false);
      setFile(null);
    }
  }

  function onChange(e: any) {
    closeMenu();
    setFile(e.target.files[0]);
  }

  return (
    <>
      <FileUploaderButton
        accept={['.csv', '.xls', '.xlsx']}
        buttonKind="secondary"
        labelText={
          <>
            <img src={Upload} />
            Import Users
          </>
        }
        disableLabelChanges
        onChange={onChange}
        className="import-users-btn"
        disabled={showLoader}
        multiple={false}
      />

      {showConfirmationModal && (
        <ConfirmationModal
          open
          onSubmit={fileUpload}
          onClose={() => {
            setShowConfirmationModal(false);
            setFile(null);
          }}
          customClassName="overwrite-users-modal"
          primaryButtonText="Yes"
          secondaryButtonText="No"
          heading="This action will delete all the existing user roles and will write the new from the selected file. Are you sure you want to proceed?"
        />
      )}
    </>
  );
};

export default ImportUserBtn;
