import { iconInfoGlyph } from '@wfp/icons';
import {
  Button,
  Icon,
  Module,
  ModuleBody,
  ModuleHeader,
  tooltipStyle,
} from '@wfp/ui';
import { Tooltip } from 'acr_ui/sources/components/Tooltip';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { sendGAEvent, showNotification } from 'src/utils';

const WordLimitPanel = ({
  data,
  dispatchWordcountConfig,
  sectionID,
  sectionTitle,
  isEditable,
}) => {
  const sections = data?.sections || [];
  const notification = data?.notification;
  const maxAllowedWords = data?.total_allowed;
  const allowedWordsFromResponse = useMemo(
    () =>
      sections.map(section => {
        return section.allowed;
      }),
    [sections],
  );

  const [loading, setLoading] = useState(false);

  const [allowedWords, setAllowedWords] = useState(allowedWordsFromResponse);
  const isSectionLocked = useMemo(() => notification !== '', [notification]);

  useEffect(() => {
    setLoading(false);
  }, [data]);

  useEffect(() => {
    setAllowedWords(allowedWordsFromResponse);
  }, [allowedWordsFromResponse]);

  const totalAllowedWords = useMemo(() => {
    return allowedWords.reduce((a, b) => a + b, 0);
  }, [allowedWords]);

  const remainingAllowedWords = useMemo(() => {
    return maxAllowedWords - totalAllowedWords;
  }, [allowedWords, maxAllowedWords]);
  // Given allowed Words array index and the operation then set new state of allowed Words
  const updateAllowedWords = useCallback(
    (index, operation) => {
      const clonedData = [...allowedWords];
      if (operation === 'sum') {
        clonedData[index] = clonedData[index] + 10;
      } else if (operation === 'subtraction') {
        clonedData[index] = clonedData[index] - 10;
      }
      setAllowedWords(clonedData);
    },
    [allowedWords, setAllowedWords],
  );

  const saveChanges = () => {
    setLoading(true);
    showNotification('Narrative limits updated successfully!', 'success');
    try {
      const requestBody = {
        total_allowed: maxAllowedWords,
        total_used: totalAllowedWords,
        total_available: remainingAllowedWords,
        sections: sections.map((section, index) => {
          return {
            section_id: section?.section_id,
            allowed: allowedWords[index],
          };
        }),
        sectionID: sectionID,
      };
      dispatchWordcountConfig({
        method: 'post',
        data: requestBody,
      });
    } catch {
      showNotification('Something went wrong! Try again later.', 'error');
    }
  };

  return (
    <Module className="word-limit-panel">
      <ModuleHeader className="word-limit-panel-header">
        <span className="title" data-test-id="word-count-title">
          Word count
          <Tooltip
            title="The word count panel allows CO coordinators to set the amount of words available for each subsection narrative. The overall word count limit is cumulative and can be redistributed between subsections as needed using the -10/+10 buttons."
            trigger="mouseenter"
            tooltipStyle={tooltipStyle}
            theme="light word-limit-panel-info"
            position="bottom"
          >
            <Icon
              className="word-limit-panel-icon-info"
              width="24"
              height="24"
              fill="#0b77c2"
              icon={iconInfoGlyph}
              description=""
              data-test-id="information-icon"
            />
          </Tooltip>
        </span>
        {!isSectionLocked && isEditable && (
          <div>
            <Button
              kind="secondary"
              onClick={() => {
                setAllowedWords(allowedWordsFromResponse);
                sendGAEvent('cancel_word_count_btn_clicked');
              }}
              data-test-id="cancel-button"
            >
              Cancel
            </Button>
            <Button
              kind="primary"
              onClick={() => {
                sendGAEvent('save_word_count_btn_clicked');
                saveChanges();
              }}
              disabled={loading}
              data-test-id="save-button"
            >
              Save
            </Button>
          </div>
        )}
      </ModuleHeader>
      <ModuleBody className="word-limit-panel-body">
        {isSectionLocked ? (
          <div>{notification}</div>
        ) : (
          <div className="word-limit-panel-wrapper">
            {sections?.map(({ section_id, section_name, used }, index) => {
              return (
                <Module className="word-limit-panel-box" key={section_id}>
                  <ModuleHeader
                    className={`word-limit-panel-header ${
                      sectionTitle === 'Cross-cutting Results'
                        ? 'wordlimit-header-static-height'
                        : null
                    }`}
                  >
                    <div>{section_name}</div>
                    <div className="word-limit-section-controls">
                      <Button
                        kind="secondary"
                        onClick={() => updateAllowedWords(index, 'subtraction')}
                        disabled={
                          allowedWords[index] - used < 10 || !isEditable
                        }
                        data-test-id="decrease-button"
                      >
                        -10
                      </Button>
                      <Button
                        kind="secondary"
                        onClick={() => updateAllowedWords(index, 'sum')}
                        disabled={remainingAllowedWords < 10 || !isEditable}
                        data-test-id="increase-button"
                      >
                        +10
                      </Button>
                    </div>
                  </ModuleHeader>
                  <ModuleBody>
                    <div className="word-limit-box-info">
                      <div data-test-id="written-words">
                        Written {used} of {allowedWords[index]}
                      </div>
                      <div className="limit-box" data-test-id="limit-label">
                        Limit: {allowedWords[index]}
                      </div>
                    </div>
                  </ModuleBody>
                </Module>
              );
            })}
            <div className="word-limit-panel-footer">
              <div className="usable-wrapper">
                <div className="usable-text" data-test-id="usable-words">
                  Usable: {remainingAllowedWords}
                </div>
                <div className="usable-resume-text" data-test-id="used-words">
                  Used: {totalAllowedWords} out of {maxAllowedWords}
                </div>
              </div>
            </div>
          </div>
        )}
      </ModuleBody>
    </Module>
  );
};

export default WordLimitPanel;
