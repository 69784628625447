import { iconWarningSolid } from '@wfp/icons';
import {
  Button,
  Icon,
  Input,
  Module,
  ModuleBody,
  ModuleFooter,
  ModuleHeader,
  Tag,
} from '@wfp/ui';
import type { FC } from 'react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setSubtitleVal } from 'src/redux/actions';

interface Props {
  initialContent: string;
  isEditable: boolean;
  sectionId: number;
}

const maxCharacterCount = 60;

const Subtitle: FC<Props> = ({
  initialContent = '',
  isEditable = true,
  sectionId,
}) => {
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [content, setContent] = useState(initialContent || '');

  const charsExceed = content?.length > maxCharacterCount;

  function resetContent() {
    setContent(initialContent);
  }

  function handleCancelBtn() {
    setIsEdit(false);
    resetContent();
  }

  function handleSaveBtn() {
    if (content !== initialContent) {
      dispatch(
        setSubtitleVal({
          val: content,
          sectionId,
        }),
      );
    }
    setIsEdit(false);
  }

  function getCharactersResume() {
    return `${content?.length || 0} / ${maxCharacterCount}`;
  }

  const SubtitleErrorMessage = () => {
    return (
      <div className="subtitle-footer subtitle-error-msg">
        <Icon icon={iconWarningSolid} fill="#E4002B" description="" />
        <div>Character limit exceed: {getCharactersResume()}</div>
      </div>
    );
  };

  return (
    <Module className="subtitle-section" data-test-id="subtitle-section">
      <ModuleHeader>
        <div className="subtitle-section-title">
          Subtitle
          <Tag type="info">Optional</Tag>
        </div>
        {isEditable && (
          <div className="subtitle-section-btns">
            {isEdit ? (
              <>
                <Button
                  onClick={handleCancelBtn}
                  kind="secondary"
                  data-test-id="cancel-button"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSaveBtn}
                  disabled={charsExceed}
                  data-test-id="save-subtitle-button"
                >
                  Save subtitle
                </Button>
              </>
            ) : (
              <Button
                onClick={() => setIsEdit(true)}
                data-test-id="edit-button"
              >
                Edit
              </Button>
            )}
          </div>
        )}
      </ModuleHeader>
      <ModuleBody>
        {isEdit ? (
          <Input name="subtitle">
            {() => (
              <input
                value={content}
                className="subtitle-input"
                placeholder="Insert subtitle"
                onChange={val => setContent(val.currentTarget.value)}
              />
            )}
          </Input>
        ) : initialContent ? (
          initialContent
        ) : (
          <div className="subtitle-empty-info">No subtitle</div>
        )}
      </ModuleBody>
      <ModuleFooter>
        {charsExceed ? (
          <SubtitleErrorMessage />
        ) : (
          <div className="subtitle-footer">
            Character count: {getCharactersResume()}
          </div>
        )}
      </ModuleFooter>
    </Module>
  );
};

export default Subtitle;
