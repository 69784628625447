import { Button, TextInput } from '@wfp/ui';
import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { msalApp } from 'src/auth/auth';

interface ICustomLoginProps {
  customLogin: (username: string, password: string) => void;
}

const pswMinChars = 8;

const CustomLoginForm: FC<ICustomLoginProps> = ({ customLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [msLoggedAccounts, setMsLoggedAccounts] = useState(null);

  const customLoginData = useSelector(
    (state: RootStateOrAny) => state.customLogin,
  );

  const formError = password.length < pswMinChars;

  async function getLoggedAccounts() {
    const accounts = await msalApp.getAllAccounts();
    setMsLoggedAccounts(accounts);
  }

  function handleLogin() {
    customLogin(username, password);
  }

  useEffect(() => {
    getLoggedAccounts();
  }, []);

  // Redirect logged users to the Dashboard
  if (customLoginData?.accessToken || !isEmpty(msLoggedAccounts)) {
    return <Redirect to="/" />;
  }

  return (
    <div className="custom-login-wrapper">
      <div className="custom-login-form-wrapper">
        <TextInput
          type="text"
          onChange={(e: any) => setUsername(e.target.value)}
          value={username}
          labelText="Username"
          placeholder="Insert username"
        />
        <TextInput
          // @ts-expect-error: Incorrect types in WFP UI Kit
          type="password"
          onChange={(e: any) => setPassword(e.target.value)}
          value={password}
          labelText="Password"
          placeholder="Insert password"
        />
        <Button onClick={handleLogin} kind="primary" disabled={formError}>
          Login
        </Button>
      </div>
    </div>
  );
};

export default CustomLoginForm;
